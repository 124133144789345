import { API_KEY } from "../configs/admin-config";

var userEmail = null;
var encodeValue = null;

export const getAPIKey = () => {
  return API_KEY;
};

export const setUserEmail = (email) => {
  userEmail = email;
};

export const getUserEmail = () => {
  return userEmail;
};

export const setEncodeValue = (value) => {
  if (value) {
    encodeValue = value;
  }
};

export const getEncodValue = () => {
  return encodeValue;
};
