import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, TextField, Radio, RadioGroup, FormControlLabel, FormControl, Grid, Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import { checkIfRoleExists } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  questionParent: {
    display: "flex",
  },
  questionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  questionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  emailMsg: {
    color: "grey",
  },
  fileAttachment: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  fileChip: {
    marginLeft: 10,
  },
  uploadButton: {
    textTransform: "none",
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 5,
    width: "100%",
  },
}));

const SubQuestionDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [question, setQuestion] = useState(props.question);
  const [subQuestion, setSubQuestion] = useState(props.subQuestion);

  const [boolAnswer, setBoolAnswer] = useState(false);
  const [uploadedFiles, setFiles] = useState([]);
  const [isTrue, setIsTrue] = useState(false);

  useEffect(() => {
    props.disableCall(props.fieldDisabled, props.answer);

    // Converting Mysql 0/1 into Boolean True/False
    if (props.answers && props.answers[0] && props.answers[0].booleanAnswer) {
      setBoolAnswer(true);
    } else if (props.answers && props.answers[0] && !props.answers[0].booleanAnswer) {
      setBoolAnswer(false);
    }

    if (props.files && props.files.length > 0) {
      props.files.map((file) => {
        if (file.subQuestionId === subQuestion.subQuestionId) {
          setFiles((prev) => [...prev, file]);
        }
        return null;
      });
    }
  }, [subQuestion.subQuestionId, subQuestion.answerType]);

  useEffect(() => {
    setQuestion(props.question);
    setSubQuestion(props.subQuestion);
  }, [props.question, props.subQuestion, props.isTrue]);

  useEffect(() => {
    if (!props.callbackAnswer && props.answer && props.answer.length) {
      if (props.answer[0]?.booleanAnswer !== undefined && props?.subQuestion?.answerType) {
        setBoolAnswer(Boolean(props.answer[0].booleanAnswer));
      }
    } else if (props.callbackAnswer) {
      if (props.callbackAnswer[0]?.booleanAnswer !== undefined && props?.subQuestion?.answerType) {
        setBoolAnswer(Boolean(props.callbackAnswer[0].booleanAnswer));
      }
    }
  }, [props.answer, props.callbackAnswer]);

  // Handle Radio Button Inputs
  const handleRadioChange = (subQuestionId) => (event) => {
    const value = event.target.value;
    // If yes is selected
    if (value === "yes") {
      setBoolAnswer(true);
      props.parentCallback(subQuestionId, true, "", "", true);
    }
    // If no is selected
    else {
      setBoolAnswer(false);
      props.parentCallback(subQuestionId, false, "", "", true);
    }
  };

  // Handle Text Inputs
  const handleFormChange = (subQuestionId) => (event) => {
    const newValue = event.target.value;
    const currentValue = props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : "";

    if (newValue !== currentValue) {
      props.parentCallback(subQuestionId, "", newValue, "", true);
    }
  };

  const handleChipOnClick = (fileName) => {
    var extension = fileName.substr(fileName.length - 4);
    var extensionWithoutDot = extension.substr(1);

    if (extension === ".pdf") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_PDF,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else if (extension === ".jpg") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_IMAGE,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else if (extension === ".png") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_IMAGE,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else {
      window.location.replace(AppConfig.FileServiceURL + fileName + "?fileExtension=" + extensionWithoutDot);
    }
  };

  return (
    <React.Fragment>
      {/* Render Boolean Input */}
      {question.questionId === subQuestion.questionId && subQuestion.answerType && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <FormControl
                component="fieldset"
                disabled={checkIfRoleExists(roles.legalApprover) ? props.disabled || props.fieldDisabled : true}
              >
                <RadioGroup
                  row
                  id={subQuestion.subQuestionId}
                  onChange={handleRadioChange(subQuestion.subQuestionId)}
                  defaultValue={props.answer && props.answer[0] ? (props.answer[0].booleanAnswer ? "yes" : "no") : ""}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label={props.categoryId === "3" ? "Approve" : "Yes"}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label={props.categoryId === "3" ? "Reject" : "No"}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </Grid>
      )}

      {/* Render Description Input for Boolean Input Yes */}
      {question.questionId === subQuestion.questionId &&
        subQuestion.answerDescription &&
        subQuestion.answerType &&
        boolAnswer && (
          <Grid item xs={12}>
            <div className={classes.questionParent}>
              <div className={classes.questionIndex}></div>
              <div className={classes.questionDetails}>
                <TextField
                  name={subQuestion.subQuestionId + ""}
                  defaultValue={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                  size="small"
                  minRows={3}
                  multiline
                  onChange={handleFormChange(subQuestion.subQuestionId)}
                  label={subQuestion.description}
                  autoComplete="nope"
                  error={props.isError}
                  helperText={props.isError ? "Required" : null}
                  variant="outlined"
                  disabled={checkIfRoleExists(roles.legalApprover) ? props.disabled || props.fieldDisabled : true}
                  fullWidth
                />
              </div>
            </div>
          </Grid>
        )}

      {/* Render Description Input Only */}
      {question.questionId === subQuestion.questionId && subQuestion.answerDescription && !subQuestion.answerType && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <TextField
                name={subQuestion.subQuestionId + ""}
                defaultValue={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                size="small"
                multiline={props.categoryId === "3"}
                minRows={props.categoryId === "3" ? 3 : 1}
                onChange={handleFormChange(subQuestion.subQuestionId)}
                label={subQuestion.description}
                autoComplete="nope"
                variant="outlined"
                disabled={checkIfRoleExists(roles.legalApprover) ? props.disabled || props.fieldDisabled : true}
                fullWidth
              />
            </div>
          </div>
        </Grid>
      )}

      {/* Render File upload Input */}

      {question.questionId === subQuestion.questionId && subQuestion.answerFileUpload && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <Grid container spacing={2}>
                {subQuestion.description !== "" && (
                  <Grid item xs={12} sm={2} lg={1} className={classes.verticalcenter}>
                    <Typography>{subQuestion.description}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={10} lg={11}>
                  <React.Fragment>
                    {uploadedFiles.map((file, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Chip
                            className={classes.fileChip}
                            label={file.fileName.substring(24, file.fileName.length).substring(0, 18)}
                            color="secondary"
                            variant="outlined"
                            onClick={() => handleChipOnClick(file.fileName)}
                          ></Chip>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default SubQuestionDetails;
