export function applyLengthValidation(input, length) {
  if (input) {
    return input.substring(0, length);
  }
  return input;
}

export function validateInputLength(input, length) {
  if (input) {
    return input.substring(0, length);
  }
  return input;
}
