import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, LinearProgress } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { AppConfig } from "../../../configs/client-config";
import { useHistory } from "react-router-dom";
import { setEncodeValue, setUserEmail, getEncodValue, getAccessToken } from "../../../utils/oauth";

var parse = require("html-react-parser");

const useStyles = makeStyles((theme) => ({
  errorMsg: {
    color: "red",
    marginTop: 15,
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 5,
    width: "100%",
  },
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEncodeValue(props.encodeValue);
  }, [props]);

  const handleClose = () => {};

  const validateEmail = async () => {
    setFieldError(false);
    setErrorMessage("");
    if (email === "") {
      setFieldError(true);
      setErrorMessage("Please enter your Email address");
    } else {
      setLoading(true);
      const requestURL = AppConfig.baseUrl + AppConfig.validateEmail + email + "?encodeValue=" + props.encodeValue;

      // Move to generalized implementation
      try {
        let response = await fetch(requestURL, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + getAccessToken(),
          },
        });
        let body = await response.json();
        if (response.status === 200) {
          if (body.message === "Invalid URL") {
            setErrorMessage(
              "Form Not Found!<br>Contact Us : <a target='_blank' href='mailto:" +
                AppConfig.financeEmail +
                "'>" +
                AppConfig.financeEmail +
                "</a>"
            );
          } else if (body.message === "Invalid Email") {
            setErrorMessage(
              "Invalid Email Address!<br>Contact Us : <a target='_blank' href='mailto:" +
                AppConfig.financeEmail +
                "'>" +
                AppConfig.financeEmail +
                "</a>"
            );
            setFieldError(true);
          }
          //If drafted data is not Available
          else if (body.message === "Success") {
            setUserEmail(email);
            history.push({
              pathname: AppConfig.urls.RESELLER_FORM + "?encodeValue=" + getEncodValue() + "&email=" + email,
              state: { isValid: true },
            });
            window.location.reload();
          }
          //If drafted data is Available
          else {
            setUserEmail(email);
            history.push({
              pathname: AppConfig.urls.RESELLER_FORM + "?encodeValue=" + getEncodValue() + "&email=" + email,
              state: { isValid: true },
            });
            window.location.reload();
          }
        } else {
          setErrorMessage("Something went wrong with the server");
          setFieldError(true);
          console.error(body.message);
        }
        setLoading(false);
        return null;
      } catch (error) {
        setErrorMessage("Something went wrong with the server");
        setFieldError(true);
        setLoading(false);
        console.error(error);
        return error;
      }
    }
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">User Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter your email address</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            onChange={emailHandler}
            error={fieldError}
          />
          {loading && <LinearProgress className={classes.linearProgress} color="secondary" />}
          <Typography variant="body2" className={classes.errorMsg}>
            {parse(errorMessage)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={validateEmail} color="secondary" size="small">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function ValidateEmail(props) {
  const handleClose = (value) => {};

  return (
    <div>
      <SimpleDialog open={true} onClose={handleClose} encodeValue={props.match.params.encodeValue} />
    </div>
  );
}
