import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    marginTop: 300,
  },
  logoSpan: {
    width: 80,
    marginRight: 20,
  },
}));

const Item = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Item className={`${classes.marginTop20}`}>
        <h1 className={classes.title}>404: Page not found</h1>
        <p style={{ textAlign: "center" }}>
          We can't seem to find the page you're looking for. Please try going back to the previous page, or contact us
          at <a href="mailto:finance-group@wso2.com">finance-group@wso2.com</a> for assistance.
        </p>
      </Item>
    </div>
  );
};

export default NotFoundPage;
