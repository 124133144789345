import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, TextField, FormControl, Select } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppConfig } from "../../../../../configs/admin-config";
import { getAccessToken } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    paddingTop: 20,
    paddingBottom: 30,
    paddingLeft: 50,
    paddingRight: 50,
    minHeight: "800px",
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "center",
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  let formStruct = {
    companyName: "",
    taxIdNumber: "",
    country: "",
    state: "",
    cityProvince: "",
    streetAddress: "",
    postalZipCode: "",
    yearOfIncorporation: "",
    noOfEmployees: "",
    noOfCustomers: "",
    websiteLink: "",
    formCompleted: "",
  };

  const [backdropOpen, setbackDrop] = useState(true);
  const [formData, setFormData] = useState(formStruct);

  const getResellerData = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.id;
    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body && body.length > 0) {
          setFormData(body[0]);
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    getResellerData();
  }, []);

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              Please provide Candidate’s full business name, aliases, and other names
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="companyName"
              className={classes.headingName}
              value={formData.companyName}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              Tax ID Number
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="taxIdNumber"
              value={formData.taxIdNumber}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              Country
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="country"
              value={formData.country}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              State
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="state"
              value={formData.state}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              City/Province
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="cityProvince"
              value={formData.cityProvince}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              Street Address
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="streetAddress"
              value={formData.streetAddress}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              Postal/Zip Code
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="postalZipCode"
              value={formData.postalZipCode}
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.verticalcenter}>
            <Typography style={{ textAlign: "justify" }} variant="body2">
              The information provided below will remain strictly confidential and used for the creditworthiness
              assessment only.
            </Typography>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.headingParent}>
                <Typography className={classes.headingIndex2}></Typography>
                <Typography style={{ textAlign: "justify" }} className={classes.headingName2}>
                  Year of incorporation
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.headingParent}>
                <Typography className={classes.headingIndex2}></Typography>
                <TextField
                  name="yearOfIncorporation"
                  value={formData.yearOfIncorporation}
                  size="small"
                  variant="outlined"
                  autoComplete="nope"
                  disabled={true}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.headingParent}>
                <Typography className={classes.headingIndex2}></Typography>
                <Typography style={{ textAlign: "justify" }} className={classes.headingName2}>
                  No of current employees
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.headingParent}>
                <Typography className={classes.headingIndex2}></Typography>
                <TextField
                  name="noOfEmployees"
                  value={formData.noOfEmployees}
                  size="small"
                  variant="outlined"
                  autoComplete="nope"
                  disabled={true}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.headingParent}>
                <Typography className={classes.headingIndex2}></Typography>
                <Typography style={{ textAlign: "justify" }} className={classes.headingName2}>
                  No of current customers
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.headingParent}>
                <Typography className={classes.headingIndex2}></Typography>
                <TextField
                  name="noOfCustomers"
                  value={formData.noOfCustomers}
                  size="small"
                  variant="outlined"
                  autoComplete="nope"
                  disabled={true}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <Typography style={{ textAlign: "justify" }} className={classes.headingName}>
              Please provide any company brochures or website address for Candidate that explains its business and
              experience.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.headingParent}>
            <Typography className={classes.headingIndex}></Typography>
            <TextField
              name="websiteLink"
              value={formData.websiteLink}
              type="url"
              size="small"
              variant="outlined"
              autoComplete="nope"
              disabled={true}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Profile;
