import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Backdrop, CircularProgress, TextField, Button, Snackbar, Grid, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import { getAccessToken, checkIfRoleExists } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    paddingLeft: "4%",
    paddingRight: "4%",
  },
  border: {
    border: "1px solid #ddd",
  },
  boldText: {
    fontWeight: 500,
  },
  tableHeading: {
    backgroundColor: "#F5F5F5",
  },
  cell1: {
    width: "70%",
    textAlign: "right",
  },
  btnContainer: {
    marginTop: 10,
  },
  submitItem: {
    textAlign: "end",
  },
  btn: {
    textTransform: "none",
  },
  tableTitle: {
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 15,
  },
  tableTitle2: {
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 15,
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WorkingsForRatios = (props) => {
  const classes = useStyles();
  const [backdropOpen, setbackDrop] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [year1, setYear1] = useState({});
  const [year2, setYear2] = useState({});
  const [year3, setYear3] = useState({});
  const [isSubmitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isEditable, setEditable] = useState(true);
  const [tableEmptySnack, setTableEmptySnack] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);

  const getCreditScoreItems = async () => {
    if (Object.keys(props.year1).length && Object.keys(props.year2).length && Object.keys(props.year3).length) {
      let year1Data = props.year1;
      let year2Data = props.year2;
      let year3Data = props.year3;

      year1Data["exchangeRate"] = parseFloat(year1Data["exchangeRate"]);
      year2Data["exchangeRate"] = parseFloat(year2Data["exchangeRate"]);
      year3Data["exchangeRate"] = parseFloat(year3Data["exchangeRate"]);

      if (props.currency === "US Dollar") {
        year1Data["exchangeRate"] = 1;
        year2Data["exchangeRate"] = 1;
        year3Data["exchangeRate"] = 1;
      }
      setYear1(year1Data);
      setYear2(year2Data);
      setYear3(year3Data);
      setDisabled(true);
      setEditable(false);
    } else {
      setYear1({
        companyId: parseInt(props.companyId),
        year: 1,
        currentAssets: 0.0,
        currentLiability: 0.0,
        cash: 0.0,
        investments: 0.0,
        totalDebt: 0.0,
        totalAssets: 0.0,
        revenue: 0.0,
        profit: 0.0,
        exchangeRate: 0.0,
      });
      setYear2({
        companyId: parseInt(props.companyId),
        year: 2,
        currentAssets: 0.0,
        currentLiability: 0.0,
        cash: 0.0,
        investments: 0.0,
        totalDebt: 0.0,
        totalAssets: 0.0,
        revenue: 0.0,
        profit: 0.0,
        exchangeRate: 0.0,
      });
      setYear3({
        companyId: parseInt(props.companyId),
        year: 3,
        currentAssets: 0.0,
        currentLiability: 0.0,
        cash: 0.0,
        investments: 0.0,
        totalDebt: 0.0,
        totalAssets: 0.0,
        revenue: 0.0,
        profit: 0.0,
        exchangeRate: 0.0,
      });
    }
    setLoading(false);
    setbackDrop(false);
  };

  useEffect(() => {
    getCreditScoreItems();
  }, []);

  const handleChange = (year, category) => (e) => {
    setSubmitted(false);
    if (year === 1) {
      let yearData = year1;
      yearData[category] = parseFloat(e.target.value);
      setYear1(yearData);
    }
    if (year === 2) {
      let yearData = year2;
      yearData[category] = parseFloat(e.target.value);
      setYear2(yearData);
    }
    if (year === 3) {
      let yearData = year3;
      yearData[category] = parseFloat(e.target.value);
      setYear3(yearData);
    }
  };

  const edit = () => {
    setDisabled(false);
    setEditable(true);
  };

  const cancel = () => {
    setDisabled(true);
    setEditable(false);
  };

  const submit = async () => {
    setSubmitted(true);
    if (checkObjectComplete(year1) && checkObjectComplete(year2) && checkObjectComplete(year3)) {
      setbackDrop(true);
      setEditable(false);
      setDisabled(true);
      let data = [];

      data.push(year1);
      data.push(year2);
      data.push(year3);

      const requestURL = AppConfig.baseUrl + AppConfig.creditScoreItems;

      try {
        let response = await fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + getAccessToken(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        let body = await response.json();
        setbackDrop(false);
        if (response.status === 200) {
          setSuccess(true);
          props.callBack();
        } else {
          console.error(body.message);
          setFail(true);
        }
        return null;
      } catch (error) {
        console.error(error);
        return error;
      }
    } else {
      setTableEmptySnack(true);
    }
  };

  const checkObjectComplete = (obj) => {
    for (var key in obj) {
      if ((obj[key] === "" || obj[key] === null) && key !== "exchangeRate") return false;
    }
    return true;
  };
  function returnCell(year, category) {
    return (
      <TableCell align="right">
        {!isLoading &&
          year === 1 &&
          (disabled ? (
            <span>{year1[category] ? parseFloat(year1[category]) : ""}</span>
          ) : (
            <TextField
              type="number"
              size="small"
              className={classes.cell1}
              onBlur={handleChange(year, category)}
              error={isSubmitted && year1[category] === ""}
              inputProps={{ min: 0, style: { textAlign: "end" } }}
              defaultValue={year1[category] ? year1[category] : ""}
            />
          ))}
        {!isLoading &&
          year === 2 &&
          (disabled ? (
            <span>{year2[category] ? parseFloat(year2[category]) : ""}</span>
          ) : (
            <TextField
              type="number"
              size="small"
              className={classes.cell1}
              onBlur={handleChange(year, category)}
              error={isSubmitted && year2[category] === ""}
              inputProps={{ min: 0, style: { textAlign: "end" } }}
              defaultValue={year2[category] ? year2[category] : ""}
            />
          ))}
        {!isLoading &&
          year === 3 &&
          (disabled ? (
            <span>{year3[category] ? parseFloat(year3[category]) : ""}</span>
          ) : (
            <TextField
              type="number"
              size="small"
              className={classes.cell1}
              onBlur={handleChange(year, category)}
              error={isSubmitted && year3[category] === ""}
              inputProps={{ min: 0, style: { textAlign: "end" } }}
              defaultValue={year3[category] ? year3[category] : ""}
            />
          ))}
      </TableCell>
    );
  }

  function returnCellUSD(year, category) {
    return (
      <TableCell align="right">
        {!isLoading && year === 1 && (
          <span>
            ${" "}
            {(Math.round((parseFloat(year1[category]) / parseFloat(year1["exchangeRate"])) * 100) / 100)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        )}
        {!isLoading && year === 2 && (
          <span>
            ${" "}
            {(Math.round((parseFloat(year2[category]) / parseFloat(year2["exchangeRate"])) * 100) / 100)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        )}
        {!isLoading && year === 3 && (
          <span>
            ${" "}
            {(Math.round((parseFloat(year3[category]) / parseFloat(year3["exchangeRate"])) * 100) / 100)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        )}
      </TableCell>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setTableEmptySnack(false);
    setSuccess(false);
    setFail(false);
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={tableEmptySnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Table can not be Empty
        </Alert>
      </Snackbar>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Fail
        </Alert>
      </Snackbar>
      {Object.keys(year1).length > 0 && Object.keys(year2).length > 0 && Object.keys(year3).length > 0 && (
        <div>
          <Typography className={classes.tableTitle}>{props.currency}</Typography>
          <TableContainer className={classes.border}>
            <Table size="small">
              <TableHead className={classes.tableHeading}>
                <TableCell>Items</TableCell>
                <TableCell align="center">Currency</TableCell>
                <TableCell align="right">Year 1</TableCell>
                <TableCell align="right">Year 2</TableCell>
                <TableCell align="right">Year 3</TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Current Assets</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "currentAssets")}
                  {returnCell(2, "currentAssets")}
                  {returnCell(3, "currentAssets")}
                </TableRow>
                <TableRow>
                  <TableCell>Current Liability</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "currentLiability")}
                  {returnCell(2, "currentLiability")}
                  {returnCell(3, "currentLiability")}
                </TableRow>
                <TableRow>
                  <TableCell>Cash</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "cash")}
                  {returnCell(2, "cash")}
                  {returnCell(3, "cash")}
                </TableRow>
                <TableRow>
                  <TableCell>Investments</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "investments")}
                  {returnCell(2, "investments")}
                  {returnCell(3, "investments")}
                </TableRow>
                <TableRow>
                  <TableCell>Total Debt</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "totalDebt")}
                  {returnCell(2, "totalDebt")}
                  {returnCell(3, "totalDebt")}
                </TableRow>
                <TableRow>
                  <TableCell>Total Assets</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "totalAssets")}
                  {returnCell(2, "totalAssets")}
                  {returnCell(3, "totalAssets")}
                </TableRow>
                <TableRow>
                  <TableCell>Revenue</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "revenue")}
                  {returnCell(2, "revenue")}
                  {returnCell(3, "revenue")}
                </TableRow>
                <TableRow>
                  <TableCell>Profit</TableCell>
                  <TableCell align="center">{props.currency}</TableCell>
                  {returnCell(1, "profit")}
                  {returnCell(2, "profit")}
                  {returnCell(3, "profit")}
                </TableRow>
                {props.currency !== "US Dollar" && (
                  <TableRow>
                    <TableCell>Exchange Rate</TableCell>
                    <TableCell align="center"></TableCell>
                    {returnCell(1, "exchangeRate")}
                    {returnCell(2, "exchangeRate")}
                    {returnCell(3, "exchangeRate")}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {checkIfRoleExists(roles.superuser) && (
            <Grid container spacing={2} className={classes.btnContainer} justify="flex-end">
              <Grid item className={classes.submitItem}>
                {!isEditable && (
                  <Button className={classes.btn} variant="outlined" size="small" color="primary" onClick={edit}>
                    Edit
                  </Button>
                )}
                {isEditable &&
                  Object.keys(year1).length > 0 &&
                  Object.keys(year2).length > 0 &&
                  Object.keys(year3).length > 0 && (
                    <Button className={classes.btn} variant="outlined" size="small" color="primary" onClick={cancel}>
                      Cancel
                    </Button>
                  )}
              </Grid>
              <Grid item className={classes.submitItem}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={submit}
                  disabled={!isEditable}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      )}
      {Object.keys(year1).length > 0 &&
        Object.keys(year2).length > 0 &&
        Object.keys(year3).length > 0 &&
        props.currency !== "US Dollar" &&
        checkObjectComplete(year1).toString() === "true" &&
        checkObjectComplete(year2).toString() === "true" &&
        checkObjectComplete(year3).toString() === "true" && (
          <div>
            {!isEditable && (
              <div>
                <Typography className={classes.tableTitle2}>US Dollar</Typography>

                <TableContainer className={classes.border}>
                  <Table size="small">
                    <TableHead className={classes.tableHeading}>
                      <TableCell>Items</TableCell>
                      <TableCell align="center">Currency</TableCell>
                      <TableCell align="right">Year 1</TableCell>
                      <TableCell align="right">Year 2</TableCell>
                      <TableCell align="right">Year 3</TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Current Assets</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "currentAssets")}
                        {returnCellUSD(2, "currentAssets")}
                        {returnCellUSD(3, "currentAssets")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Current Liability</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "currentLiability")}
                        {returnCellUSD(2, "currentLiability")}
                        {returnCellUSD(3, "currentLiability")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Cash</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "cash")}
                        {returnCellUSD(2, "cash")}
                        {returnCellUSD(3, "cash")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Investments</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "investments")}
                        {returnCellUSD(2, "investments")}
                        {returnCellUSD(3, "investments")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Debt</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "totalDebt")}
                        {returnCellUSD(2, "totalDebt")}
                        {returnCellUSD(3, "totalDebt")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Assets</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "totalAssets")}
                        {returnCellUSD(2, "totalAssets")}
                        {returnCellUSD(3, "totalAssets")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Revenue</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "revenue")}
                        {returnCellUSD(2, "revenue")}
                        {returnCellUSD(3, "revenue")}
                      </TableRow>
                      <TableRow>
                        <TableCell>Profit</TableCell>
                        <TableCell align="center">USD</TableCell>
                        {returnCellUSD(1, "profit")}
                        {returnCellUSD(2, "profit")}
                        {returnCellUSD(3, "profit")}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )}
    </React.Fragment>
  );
};

export default WorkingsForRatios;
