import React, { useEffect, useState } from "react";
import { AppBar, Paper, Grid, Chip, Button, Backdrop, Snackbar, CircularProgress, IconButton } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ResellerSummary from "./Finance/Finance";
import Legal from "./Legal/Legal";
import Profile from "./Profile/Profile";
import Approval from "./Approval/Approval";
import { NavLink, useHistory } from "react-router-dom";
import { AppConfig, roles } from "../../../../configs/admin-config";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import DoneIcon from "@material-ui/icons/Done";
import CreditScore from "./CreditScore/CreditScore";
import { getAccessToken, checkIfRoleExists } from "../../../../utils/oauth";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    paddingTop: "48px",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
  },
  container: {
    flexGrow: 1,
    paddingBottom: 30,
    paddingTop: 30,
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  formStatusGrid: {
    marginBottom: 20,
  },
  appbar: {
    backgroundColor: theme.palette.primary.main,
  },
  tabs: {
    textTransform: "none",
  },
  tabPanel: {
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "white",
    textTransform: "none",
  },
  buttonGrid: {
    textAlign: "end",
  },
}));

const ResellerDashboard = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [backdropOpen, setbackDrop] = useState(true);
  const [copySnack, setCopy] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [value, setValue] = React.useState(0);
  const [formRender, setFormRender] = React.useState(0);
  const [companyData, setCompanyData] = React.useState({});
  const [approvalEmail, setApprovalEmail] = useState(false);

  const getResellerData = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.match.params.id;
    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body && body.length > 0) {
          setCompanyData(body[0]);
        } else {
          console.error(body.message);
          history.push({
            pathname: AppConfig.urls.NOT_FOUND_PAGE,
          });
        }
      } else {
        console.error(body.message);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const pushToNoAccessPage = () => {
    history.push({
      pathname: AppConfig.urls.NO_ACCESS_PAGE,
    });
  };

  const switchToTab = () => {
    if (props.match.params.tabName === "profile") {
      setValue(0);
    } else if (props.match.params.tabName === "finance") {
      if (checkIfRoleExists(roles.finance)) {
        setValue(1);
      } else {
        pushToNoAccessPage();
      }
    } else if (props.match.params.tabName === "legal") {
      if (
        checkIfRoleExists(roles.legal) ||
        checkIfRoleExists(roles.financeCreator) ||
        checkIfRoleExists(roles.financeReviewer) ||
        checkIfRoleExists(roles.financeApprover)
      ) {
        setValue(2);
      } else {
        pushToNoAccessPage();
      }
    } else if (props.match.params.tabName === "creditscore") {
      if (checkIfRoleExists(roles.finance)) {
        setValue(3);
      } else {
        pushToNoAccessPage();
      }
    } else if (props.match.params.tabName === "approvalsummary") {
      setValue(4);
    } else {
      pushToNoAccessPage();
    }
  };

  useEffect(() => {
    getResellerData();
    switchToTab();
  }, [formRender]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openProfileTab = () => {
    history.replace({
      pathname:
        AppConfig.urls.ADMIN +
        AppConfig.urls.RESELLER_DASHBOARD +
        props.match.params.id +
        "/" +
        (AppConfig.resellerDashboardTabs[0] ? AppConfig.resellerDashboardTabs[0] : ""),
    });
  };

  const openFinanceTab = () => {
    history.replace({
      pathname:
        AppConfig.urls.ADMIN +
        AppConfig.urls.RESELLER_DASHBOARD +
        props.match.params.id +
        "/" +
        (AppConfig.resellerDashboardTabs[1] ? AppConfig.resellerDashboardTabs[1] : ""),
    });
  };

  const openLegalTab = () => {
    history.replace({
      pathname:
        AppConfig.urls.ADMIN +
        AppConfig.urls.RESELLER_DASHBOARD +
        props.match.params.id +
        "/" +
        (AppConfig.resellerDashboardTabs[2] ? AppConfig.resellerDashboardTabs[2] : ""),
    });
  };

  const openApprovalSummaryTab = () => {
    history.replace({
      pathname:
        AppConfig.urls.ADMIN +
        AppConfig.urls.RESELLER_DASHBOARD +
        props.match.params.id +
        "/" +
        (AppConfig.resellerDashboardTabs[3] ? AppConfig.resellerDashboardTabs[3] : ""),
    });
  };

  const openCreditScoreTab = () => {
    history.replace({
      pathname:
        AppConfig.urls.ADMIN +
        AppConfig.urls.RESELLER_DASHBOARD +
        props.match.params.id +
        "/" +
        (AppConfig.resellerDashboardTabs[4] ? AppConfig.resellerDashboardTabs[4] : ""),
    });
  };

  const allowEditAccess = (partner) => async (e) => {
    setbackDrop(true);
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + partner.companyId + "/" + AppConfig.formStatus;
    let data = {
      formStatus: "edit_requested",
      clientUrl: AppConfig.externalUrl,
    };
    try {
      let response = await fetch(requestURL, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      setbackDrop(false);
      if (response.status === 200) {
        setSuccess(true);
        setFormRender(formRender + 1);
      } else {
        console.error(body.message);
        setFail(true);
      }
      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const sendApprovalEmail = (companyData) => async (e) => {
    setbackDrop(true);
    const requestURL = AppConfig.baseUrl + AppConfig.sendApprovalEmail;
    let data = {
      name: companyData.companyName,
      id: companyData.companyId,
    };
    try {
      let response = await fetch(requestURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      setbackDrop(false);
      if (response.status === 200) {
        setSuccess(true);
        setApprovalEmail(true);
      } else {
        throw new Error("Failed to send email");
      }
      return null;
    } catch (error) {
      setFail(true);
      return error;
    }
  };

  const copyURL = (e) => {
    e.stopPropagation();
    const externalUrl = AppConfig.externalUrl;
    const linkURL = externalUrl + "partners/validate/" + props.location.state.encodeString;
    let el = document.createElement("textarea");
    el.value = linkURL;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopy(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setCopy(false);
  };

  return (
    <div className={classes.root}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={copySnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Copied to Clipboard!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Failed!
        </Alert>
      </Snackbar>

      <div className={classes.container}>
        <Grid container spacing={1} className={classes.formStatusGrid}>
          <Grid item xs={12}>
            <Grid container direction="row" justifycontent="flex-start" alignItems="center">
              <Grid item>
                <NavLink to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE}>
                  <IconButton aria-label="back">
                    <ArrowBackIcon />
                  </IconButton>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={8} style={{ textAlign: "justify" }}>
            <Chip
              variant="outlined"
              label={
                companyData.formStatus === "completed"
                  ? "Form Status : Completed"
                  : companyData.formStatus === "edit_requested"
                  ? "Form Status : Requested to Edit"
                  : companyData.formStatus === "drafted"
                  ? "Form Status : Drafted"
                  : "Form Status : Pending"
              }
              color={"primary"}
            />
          </Grid>
          {/* <Grid item xs={12} md={3} lg={4} className={classes.buttonGrid}>
              {props.location.state && (checkIfRoleExists(roles.admin) || checkIfRoleExists(roles.channelManager)) 
                && props.location.state.encodeString!=="" && (
                <Button 
                  className={classes.button} 
                  variant="outlined" 
                  color="primary" 
                  onClick={copyURL} 
                  startIcon={<LinkOutlinedIcon />}>Generate Form URL</Button>
              )}
          </Grid> */}
          {checkIfRoleExists(roles.admin) && (
            <Grid item xs={12} md={6} lg={12} className={classes.buttonGrid}>
              {companyData.formStatus === "drafted" ||
              companyData.formStatus === "active" ||
              companyData.formStatus === "edit_requested" ? (
                <Button className={classes.button} variant="outlined" color="primary" startIcon={<DoneIcon />} disabled>
                  Editing Allowed
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={allowEditAccess(companyData)}
                  startIcon={<EditIcon />}
                >
                  Allow Editing
                </Button>
              )}
            </Grid>
          )}
          {checkIfRoleExists(roles.finance) && props.match.params.tabName === "finance" && (
            <Grid item xs={12} md={6} lg={12} className={classes.buttonGrid}>
              <Button
                className={classes.saveButton}
                variant="contained"
                color="secondary"
                size="small"
                onClick={approvalEmail === false ? sendApprovalEmail(companyData) : undefined}
                disabled={approvalEmail !== false}
                startIcon={approvalEmail === false ? <EmailIcon /> : undefined}
              >
                {approvalEmail === false ? "Send for approval" : "Sent for approval"}
              </Button>
            </Grid>
          )}
        </Grid>

        <Paper>
          <AppBar position="static" className={classes.appbar}>
            <Tabs value={value} onChange={handleChange} scrollButtons="auto">
              <Tab className={classes.tabs} companyData={companyData} label="Profile" onClick={openProfileTab} />
              <Tab
                className={classes.tabs}
                label="Finance"
                onClick={openFinanceTab}
                disabled={!checkIfRoleExists(roles.finance)}
              />
              <Tab
                className={classes.tabs}
                label="Legal"
                onClick={openLegalTab}
                disabled={
                  !(
                    checkIfRoleExists(roles.legal) ||
                    checkIfRoleExists(roles.financeCreator) ||
                    checkIfRoleExists(roles.financeReviewer) ||
                    checkIfRoleExists(roles.financeApprover)
                  )
                }
              />
              <Tab
                className={classes.tabs}
                label="Credit Score"
                onClick={openCreditScoreTab}
                disabled={!checkIfRoleExists(roles.superuser)}
              />
              <Tab className={classes.tabs} label="Approval" onClick={openApprovalSummaryTab} />
            </Tabs>
          </AppBar>
          <TabPanel className={classes.tabPanel} value={value} index={0}>
            <Profile id={props.match.params.id} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={value} index={1}>
            <ResellerSummary companyId={props.match.params.id} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={value} index={2}>
            <Legal companyId={props.match.params.id} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={value} index={3}>
            <CreditScore companyId={props.match.params.id} />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={value} index={4}>
            <Approval companyId={props.match.params.id} />
          </TabPanel>
        </Paper>
      </div>
    </div>
  );
};

export default ResellerDashboard;
