import React, { useEffect, useState } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from "@material-ui/core";
import { AppConfig } from "../../configs/client-config";
import ClientHeader from "../../components/client-app/Header";
import ResellerForm from "../../components/client-app/ResellerForm/ResellerForm";
import ClientNotFoundPage from "../../components/client-app/NotFoundPage";
import ClientTradeReferenceForm from "../../components/client-app/TradeReferenceForm/TradeReferenceForm";
import ValidateEmailTradeRef from "../../components/client-app/TradeReferenceForm/ValidateEmailTradeRef";
import ValidateEmail from "../../components/client-app/ResellerForm/ValidateEmail";
import queryString from "query-string";

const theme = createTheme({
  palette: {
    primary: {
      light: "#d7e2de",
      main: "#465867",
      dark: "#212a32",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffc808",
      main: "#ff7300",
      contrastText: "#fff",
    },
    text: {
      white: "#ffffff",
    },
  },
});

const Client = () => {
  return (
    <div className="clientApp">
      <MuiThemeProvider theme={theme}>
        <ClientHeader />
        <Router>
          <Switch>
            <Route
              exact
              path={AppConfig.urls.RESELLER_FORM_VALIDATE + ":encodeValue"}
              component={ValidateEmail}
            ></Route>
            <Route
              exact
              path={AppConfig.urls.RESELLER_FORM}
              render={({ match, location, history }) => {
                let queryParams = queryString.parse(location.search);
                let encodeValue = queryParams.encodeValue;
                let email = queryParams.email;
                return (
                  <>
                    {encodeValue && email ? (
                      <ResellerForm encodeValue={encodeValue} email={email} match={match} location={location} />
                    ) : (
                      <ClientNotFoundPage />
                    )}
                  </>
                );
              }}
            />
            <Route
              exact
              path={AppConfig.urls.TRADE_REFERENCE_FORM_VALIDATE + ":encodeValue"}
              component={ValidateEmailTradeRef}
            ></Route>
            <Route
              exact
              path={AppConfig.urls.TRADE_REFERENCE_FORM}
              render={({ match, location, history }) => {
                let queryParams = queryString.parse(location.search);
                let encodeValue = queryParams.encodeValue;
                let email = queryParams.email;
                return (
                  <>
                    {encodeValue && email ? (
                      <ClientTradeReferenceForm
                        encodeValue={encodeValue}
                        email={email}
                        match={match}
                        location={location}
                      />
                    ) : (
                      <ClientNotFoundPage />
                    )}
                  </>
                );
              }}
            />
            <Route path={AppConfig.urls.NOT_FOUND_PAGE} component={ClientNotFoundPage} />
            <Redirect exact from={AppConfig.urls.CLIENT} to={AppConfig.urls.NOT_FOUND_PAGE} />
            <Redirect exact from={"/"} to={AppConfig.urls.ADMIN} />

            {/* TODO: NOT FOUND IF NOT CLIENT PATH OR ADMIN PATH */}
          </Switch>
        </Router>
      </MuiThemeProvider>
    </div>
  );
};

export default Client;
