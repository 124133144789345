import React, { useState, useEffect } from "react";
import useHttp from "../../../utils/http";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  Paper,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  Snackbar,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MuiAlert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AppConfig } from "../../../configs/client-config";
import QuestionDetails from "./QuestionDetails";
import {
  getUserEmail,
  setEncodeValue as setGlobalEncodeValue,
  getEncodValue,
  setUserEmail,
} from "../../../utils/utils";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  backgroundColor: {
    backgroundColor: "#F7F7F7",
    minHeight: "800px",
  },
  container: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  title: {
    textAlign: "center",
    paddingTop: 35,
    marginBottom: 30,
  },
  justifyText: {
    textAlign: "justify",
  },
  accordionSummary: {
    padding: "0% 4%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  headingName: {
    textAlign: "justify",
    fontWeight: "bold",
  },
  accordionDetails: {
    padding: "1.5% 4%",
    paddingBottom: 30,
  },
  buttonGrid: {
    marginTop: 15,
    marginBottom: 2,
  },
  submitButton: {
    color: theme.palette.text.white,
  },
  button: {
    backgroundColor: "white",
  },
  pageError: {
    textAlign: "center",
    paddingTop: 70,
    fontSize: 19,
  },
}));

const ClientTradeReferenceForm = (props) => {
  const classes = useStyles();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [backdropOpen, setbackDrop] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setExpanded] = useState(true);
  const [formRender, setFormRender] = useState(0);
  const [successSnack, setSuccess] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formErrorSnack, setFormError] = useState(false);
  const [agreeConditionWarn, setAgreeConditionWarn] = useState(false);
  const [dataRetrieveSnack, setDataRetrieveSnack] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [question, setQuestion] = useState([]);
  const [subQuestion, setSubQuestion] = useState([]);
  const [resellerData, setResellerData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [checkBox, setCheckBox] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [TradeRefLinkData, setTradeRefLinkData] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  const [isDrafted, setDrafted] = useState(false);
  const [email, setEmail] = useState(null);
  const [encodeValue, setEncodeValue] = useState(null);

  useEffect(() => {
    if (props.encodeValue && props.email) {
      setGlobalEncodeValue(props.encodeValue);
      setEncodeValue(props.encodeValue);
      setUserEmail(props.email);
      setEmail(props.email);
    }
  }, [props.encodeValue, props.email]);

  const getResellerData = async () => {
    const requestURL =
      AppConfig.baseUrl +
      AppConfig.getResellerDataByTradeRefLink +
      "?encodeValue=" +
      getEncodValue() +
      "&email=" +
      getUserEmail();

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body && body.length > 0) {
          setResellerData(body[0]);
        } else {
          console.warn("Not retrieved any data from backend");
        }
        getTradeReferenceData();
      } else {
        setDataRetrieveSnack(true);
        console.error(body.message);
      }

      return null;
    } catch (error) {
      setDataRetrieveSnack(true);
      console.error(error);
      return error;
    }
  };

  const getTradeReferenceData = async () => {
    const requestURL =
      AppConfig.baseUrl + AppConfig.getTradeReferenceData + "/" + getUserEmail() + "?encodeValue=" + getEncodValue();

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          setAnswers(body.data);
          setFormStatus(body.linkData[0].status);
          setTradeRefLinkData(body.linkData[0]);
          if (body.linkData[0].status === "completed" || body.linkData[0].status === "pending") {
            setFormCompleted(true);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        setDataRetrieveSnack(true);
        console.error(body.message);
      }

      getTradeReferenceInfoQuestion();
      return null;
    } catch (error) {
      setDataRetrieveSnack(true);
      console.error(error);
      return error;
    }
  };

  const getTradeReferenceInfoQuestion = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.getTradeReferenceQuestionData;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          if (body.question) {
            setQuestion(body.question);
          }
          if (body.subQuestion) {
            setSubQuestion(body.subQuestion);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        setDataRetrieveSnack(true);
        console.error(body.message);
      }
      setLoading(false);
      setbackDrop(false);
      return null;
    } catch (error) {
      setDataRetrieveSnack(true);
      setLoading(false);
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    // if (!(props.location && props.location.state && props.location.state.isValid)) {
    //     history.push({
    //         pathname: AppConfig.urls.NOT_FOUND_PAGE
    //     })
    // }
    // else {
    setbackDrop(true);
    getResellerData();
    // }

    if (props.location && props.location.state && props.location.setSuccessMsg) {
      setSuccess(true);
    }
  }, [formRender]);

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.validateTradeRefEmail:
        if (data.data) {
        } else {
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  const dateCallback = (subQuestionId, questionId, answer, isValid) => {
    setAnswers(answers.filter((item) => item.subQuestionId !== subQuestionId));
    if (isValid) {
      try {
        setAnswers((prev) => [
          ...prev,
          {
            subQuestionId: subQuestionId,
            questionId: questionId,
            answer: answer.toLocaleDateString(),
          },
        ]);
      } catch (e) {
        // No logging for error
      }
    }
  };

  const callback = (subQuestionId, questionId, answer, isValid) => {
    setAnswers(answers.filter((item) => item.subQuestionId !== subQuestionId));
    if (isValid) {
      setAnswers((prev) => [
        ...prev,
        {
          subQuestionId: subQuestionId,
          questionId: questionId,
          answer: answer,
        },
      ]);
    }
  };

  const saveDraft = () => {
    setDrafted(true);
    setSubmitted(false);
    let isValidated = true;
    const validatingSubQuestionList = [29, 39, 40, 41];

    validatingSubQuestionList.map((item) => {
      let result = false;
      answers.map((answer) => {
        if (item === answer.subQuestionId) {
          result = true;
        }
      });
      if (!result) {
        isValidated = false;
      }
    });

    if (!isValidated) {
      setFormError(true);
    } else {
      setbackDrop(true);
      submitData("drafted");
    }
  };

  const validateRequiredAnswers = () => {
    let finalResult = true;

    subQuestion.map((subQuestion) => {
      if (subQuestion.answerRequired) {
        let result = answers.filter((answer) => answer.subQuestionId === subQuestion.subQuestionId);
        if (result.length === 0) {
          finalResult = false;
        }
      }
    });
    return finalResult;
  };

  const submitForm = () => {
    setDialogOpen(false);
    setSubmitted(true);
    if (validateRequiredAnswers()) {
      if (checkBox) {
        setbackDrop(true);
        submitData("completed");
      } else {
        setAgreeConditionWarn(true);
      }
    } else {
      setFormError(true);
    }
  };

  const submitData = async (formStatus) => {
    const requestURL = AppConfig.baseUrl + AppConfig.insertTradeRefInfo + getUserEmail();

    const data = {
      answers: answers,
      formStatus: formStatus,
      encodeValue: getEncodValue(),
    };

    try {
      let response = await fetch(requestURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      if (response.status === 200) {
        setSuccess(true);
        setFormRender(formRender + 1);
      } else {
        console.error(body.message);
        setFail(true);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      setFail(true);
      return error;
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const openDialog = () => {
    setSubmitted(true);
    if (validateRequiredAnswers()) {
      if (checkBox) {
        setDialogOpen(true);
      } else {
        setAgreeConditionWarn(true);
      }
    } else {
      setFormError(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setFormError(false);
    setAgreeConditionWarn(false);
    setDataRetrieveSnack(false);
  };
  const handleCheckbox = (event) => {
    setCheckBox(event.target.checked);
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>
      <Snackbar open={formErrorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please check your form again!
        </Alert>
      </Snackbar>
      <Snackbar open={agreeConditionWarn} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please accept the conditions
        </Alert>
      </Snackbar>
      <Snackbar open={dataRetrieveSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Something went wrong while retrieving data
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>{"Do you want to submit the form?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            After you submit the form you can not change the details later. Are you sure you want to submit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={submitForm} color="secondary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {!loading && (
        <div className={classes.backgroundColor}>
          <Container className={classes.container}>
            <div>
              <div>
                <div className={classes.title}>
                  <Typography variant="h5">Request for Trade Reference</Typography>
                </div>
              </div>
              <Paper className={classes.paper}>
                <Accordion
                  className={classes.accordion}
                  expanded={isExpanded}
                  onChange={() => {
                    setExpanded(!isExpanded);
                  }}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.headingName}>Trade Reference Information</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>
                          <Typography variant="body2">Regarding</Typography>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          value={resellerData && resellerData.companyName}
                          disabled
                          size="small"
                          label="Reference Company Name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          value={resellerData && resellerData.country}
                          disabled
                          size="small"
                          label="Country"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      {resellerData && resellerData.state !== "" && (
                        <Grid item xs={12} lg={6}>
                          <TextField
                            value={resellerData && resellerData.state}
                            disabled
                            size="small"
                            label="State"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} lg={6}>
                        <TextField
                          value={resellerData && resellerData.cityProvince}
                          disabled
                          size="small"
                          label="City/Province"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          value={resellerData && resellerData.streetAddress}
                          disabled
                          size="small"
                          label="Street Address"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      {question.length > 0 &&
                        subQuestion.length > 0 &&
                        question.map((question) => {
                          return (
                            <QuestionDetails
                              subQuestion={subQuestion}
                              question={question}
                              key={question.questionId}
                              parentCallback={callback}
                              dateCallback={dateCallback}
                              isSubmitted={isSubmitted}
                              isDrafted={isDrafted}
                              answers={answers}
                              formCompleted={formCompleted}
                            />
                          );
                        })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>

              <Grid className={classes.buttonGrid} container direction={"row"} spacing={2}>
                {(formStatus === "drafted" || formStatus === "active") && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox onChange={handleCheckbox} checked={checkBox} name="checkBox" />}
                        label="I hereby confirm to WSO2 that the information provided herein is true, accurate and complete and that the documents submitted along with this form are genuine"
                      ></FormControlLabel>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <Button
                        className={classes.submitButton}
                        variant="contained"
                        color="secondary"
                        onClick={openDialog}
                        fullWidth
                      >
                        SUBMIT
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={2}>
                      <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={saveDraft}
                      >
                        SAVE DRAFT
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClientTradeReferenceForm;
