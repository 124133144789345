import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  Paper,
  Snackbar,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AppConfig } from "../../../../../configs/admin-config";
import QuestionDetails from "./QuestionDetails";
import { getAccessToken } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
  },
  backgroundColor: {
    minHeight: "800px",
  },
  container: {
    flexGrow: 1,
    paddingBottom: 15,
  },
  justifyText: {
    textAlign: "justify",
  },
  body: {
    marginTop: 25,
    marginBottom: 25,
  },
  paper: {
    marginTop: 20,
  },
  accordion: {},
  accordionSummary: {
    padding: "0% 4%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  accordionDetails: {
    padding: "1.5% 4%",
    paddingBottom: 30,
  },
  headingParent: {
    display: "flex",
  },
  headingIndex: {
    flexBasis: "4%",
    fontWeight: "bold",
    "@media(max-width: 900px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName: {
    flexBasis: "96%",
    textAlign: "justify",
    fontWeight: "bold",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonGrid: {
    marginTop: 15,
    marginBottom: 2,
  },
  button: {
    backgroundColor: "white",
  },
  radioFormControl: {
    marginTop: 10,
  },
  radioGrid: {
    marginTop: 15,
    marginBottom: 10,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Legal = (props) => {
  const classes = useStyles();
  const [backdropOpen, setbackDrop] = useState(true);
  const [successSnack, setSuccess] = useState(false);
  const [formErrorSnack, setFormError] = useState(false);
  const [agreeConditionWarn, setAgreeConditionWarn] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [formRender, setFormRender] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [submitFiles, setSubmitFiles] = useState([]);
  const [closed, setClosed] = useState([]);

  const getLegalInfoQuestions = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.companyId + "/" + AppConfig.legalQuestions;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          if (body.subQuestions) {
            setSubQuestions(body.subQuestions);
          }
          if (body.questions) {
            setQuestions(body.questions);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  const getLegalAnswers = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.companyId + "/" + AppConfig.legalAnswers;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();

      if (response.status === 200) {
        if (body) {
          if (body.answers.length > 0) {
            setAnswers(body.answers);
          }
          if (body.files.length > 0) {
            setSubmitFiles(body.files);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }
      getLegalInfoQuestions();

      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  useEffect(() => {
    setbackDrop(true);
    getLegalAnswers();
  }, [formRender]);

  const formRenderCallback = () => {
    setFormRender(formRender + 1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setFormError(false);
    setAgreeConditionWarn(false);
  };

  const handleChange = (index) => {
    if (closed.includes(index)) {
      setClosed(closed.filter((item) => item !== index));
    } else {
      setClosed((prev) => [...prev, index]);
    }
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>
      <Snackbar open={formErrorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please check your form again!
        </Alert>
      </Snackbar>
      <Snackbar open={agreeConditionWarn} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please accept the conditions
        </Alert>
      </Snackbar>

      <div className={classes.backgroundColor}>
        <Container className={classes.container}>
          {subQuestions.length > 0 && (
            <form noValidate autoComplete="off">
              <Paper className={classes.paper}>
                <Accordion
                  className={classes.accordion}
                  expanded={!closed.includes(1)}
                  onChange={() => handleChange(1)}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  >
                    <Grid item xs={12} className={classes.headingParent}>
                      <Typography className={classes.headingIndex}>1.</Typography>
                      <Typography className={classes.headingName}>Legal Documents</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={1}>
                      {questions.length > 0 &&
                        subQuestions.length > 0 &&
                        questions.map((question) => {
                          return question.categoryId === 4 ? (
                            <QuestionDetails
                              subQuestions={subQuestions}
                              categoryId="1"
                              question={question}
                              answers={answers}
                              key={question.questionId}
                              files={submitFiles}
                              disabled={true}
                            />
                          ) : null;
                        })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>

              <Paper className={classes.paper}>
                <Accordion
                  className={classes.accordion}
                  expanded={!closed.includes(2)}
                  onChange={() => handleChange(2)}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  >
                    <Grid item xs={12} className={classes.headingParent}>
                      <Typography className={classes.headingIndex}>2.</Typography>
                      <Typography className={classes.headingName}>Legal Information</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={1}>
                      {questions.length > 0 &&
                        subQuestions.length > 0 &&
                        questions.map((question) => {
                          return question.categoryId === 2 ? (
                            <QuestionDetails
                              subQuestions={subQuestions}
                              categoryId="2"
                              question={question}
                              answers={answers}
                              key={question.questionId}
                              files={submitFiles}
                              disabled={true}
                            />
                          ) : null;
                        })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>

              <Paper className={classes.paper}>
                <Accordion
                  className={classes.accordion}
                  expanded={!closed.includes(3)}
                  onChange={() => handleChange(3)}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  >
                    <Grid item xs={12} className={classes.headingParent}>
                      <Typography className={classes.headingIndex}>3.</Typography>
                      <Typography className={classes.headingName}>Legal Opinion</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={1}>
                      {questions.length > 0 &&
                        subQuestions.length > 0 &&
                        questions.map((question) => {
                          return question.categoryId === 9 ? (
                            <QuestionDetails
                              subQuestions={subQuestions}
                              companyId={props.companyId}
                              categoryId="3"
                              question={question}
                              answers={answers}
                              key={question.questionId}
                              formRender={formRenderCallback}
                              files={submitFiles}
                              disabled={false}
                            />
                          ) : null;
                        })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </form>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Legal;
