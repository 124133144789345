import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button, Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SubQuestionDetails from "./SubQuestionDetails";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import { getAccessToken, getIdToken, checkIfRoleExists } from "../../../../../utils/oauth";

var parse = require("html-react-parser");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  descriptionParent: {
    display: "flex",
  },

  descriptionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  descriptionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "95%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400p6x)": {
      flexBasis: "85%",
    },
  },
  buttonGrid: {
    marginTop: 10,
    marginBottom: 5,
    paddingLeft: "4.3%",
    "@media(max-width: 900px)": {
      paddingLeft: "5%",
    },
    "@media(max-width: 600px)": {
      paddingLeft: "10%",
    },
    "@media(max-width: 400px)": {
      paddingLeft: "15%",
    },
  },
  saveButton: {
    textTransform: "none",
  },
  editButton: {
    left: 15,
    textTransform: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const QuestionDetails = (props) => {
  const classes = useStyles();
  const question = props.question;
  const subQuestions = props.subQuestions;
  const files = props.files;

  const [backdropOpen, setbackDrop] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [errorSnack, setErrorSnack] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [callbackAnswer, setCallbackAnswer] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [isError, setError] = useState(false);
  // const [legalResult, setLegalResult] = useState("");
  const [isTrue, setIsTrue] = useState(undefined);

  useEffect(() => {
    // setAnswers(props.answers)
  }, [props.answers]);

  const callback = (subQuestionId, booleanAnswer, descriptionAnswer, uploadFiles, isValid) => {
    setIsTrue(booleanAnswer);
    setIsValid(isValid);
    setAnswers(answers.filter((item) => item.subQuestionId !== subQuestionId));

    if (isValid) {
      setError(false);
      setAnswers((prev) => [
        ...prev,
        {
          subQuestionId: subQuestionId,
          questionId: question.questionId,
          booleanAnswer: Boolean(booleanAnswer),
          descriptionAnswer: descriptionAnswer,
          companyId: props.companyId,
        },
      ]);

      setCallbackAnswer([
        {
          subQuestionId: subQuestionId,
          questionId: question.questionId,
          booleanAnswer: Boolean(booleanAnswer),
          descriptionAnswer: descriptionAnswer,
          companyId: props.companyId,
        },
      ]);
    }
  };

  const saveData = async () => {
    if (isValid) {
      setbackDrop(true);
      setFieldDisabled(true);
      const requestURL = AppConfig.baseUrl + AppConfig.legalApproval;
      const data = {
        answers: callbackAnswer,
        token: getIdToken(),
        legalResult: isTrue === true ? "approved" : isTrue === false ? "rejected" : null,
      };

      try {
        let response = await fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAccessToken(),
          },
          body: JSON.stringify(data),
        });
        let body = await response.json();
        if (response.status === 200) {
          setSuccess(true);
          setIsValid(false);
          props.formRender();
        } else {
          console.error(body.message);
          setErrorSnack(true);
        }
        setbackDrop(false);
        return null;
      } catch (error) {
        console.error(error);
        return error;
      }
    } else {
      setError(true);
    }
  };

  const editField = () => {
    setFieldDisabled(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setErrorSnack(false);
  };

  const disableCall = (isDisable, answer) => {
    if (answer.length > 0 || props.answers.some((answer) => answer.questionId === 45)) {
      setFieldDisabled(true);
    } else {
      setFieldDisabled(isDisable);
    }
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Failed!
        </Alert>
      </Snackbar>

      <React.Fragment>
        <Grid item xs={12}>
          <div className={classes.descriptionParent}>
            <Typography className={classes.descriptionIndex}></Typography>
            <Typography variant="body2" className={classes.descriptionDetails}>
              {parse(question.questionHeading)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.verticalcenter}>
          <div className={classes.descriptionParent}>
            <Typography className={classes.descriptionIndex}>
              {props.categoryId}.{question.sortIndex}
            </Typography>
            <Typography className={classes.descriptionDetails}>{parse(question.questionDescription)}</Typography>
          </div>
        </Grid>
        {subQuestions.length > 0 &&
          subQuestions.map((subQuestion) => {
            return subQuestion.questionId === question.questionId ? (
              <SubQuestionDetails
                key={subQuestion.subQuestionId}
                categoryId={props.categoryId}
                question={question}
                subQuestion={subQuestion}
                files={files}
                parentCallback={callback}
                formCompleted={props.formCompleted}
                isSubmitted={props.isSubmitted}
                isDrafted={props.isDrafted}
                disabled={props.disabled}
                disableCall={disableCall}
                isError={isError}
                fieldDisabled={fieldDisabled}
                answer={
                  props.answers && props.answers.filter((answer) => answer.subQuestionId === subQuestion.subQuestionId)
                }
                isTrue={isTrue}
                callbackAnswer={callbackAnswer}
              />
            ) : null;
          })}

        {props.categoryId === "3" && checkIfRoleExists(roles.legalApprover) && (
          <div className={classes.buttonGrid} container>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="secondary"
              disabled={fieldDisabled || !isValid}
              size="small"
              onClick={saveData}
            >
              Save
            </Button>
            <Button
              className={classes.editButton}
              variant="outlined"
              color="primary"
              disabled={!fieldDisabled}
              size="small"
              onClick={editField}
            >
              Edit
            </Button>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default QuestionDetails;
