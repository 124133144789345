import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import SubQuestionDetails from "./SubQuestionDetails";

var parse = require("html-react-parser");

const useStyles = makeStyles((theme) => ({
  questionHeading: {
    marginTop: 15,
  },
}));

const QuestionDetails = (props) => {
  const classes = useStyles();
  const question = props.question;
  const subQuestions = props.subQuestion;

  const callback = (subQuestionId, answer, isValid) => {
    props.parentCallback(subQuestionId, question.questionId, answer, isValid);
  };

  const dateCallback = (subQuestionId, answer, isValid) => {
    props.dateCallback(subQuestionId, question.questionId, answer, isValid);
  };

  return (
    <React.Fragment>
      {question.questionHeading !== "" && (
        <Grid item xs={12}>
          <div>
            <Typography className={classes.questionHeading} variant="body2">
              {question.questionHeading}
            </Typography>
          </div>
        </Grid>
      )}
      {question.questionDescription !== "" && (
        <Grid item xs={12}>
          <div>
            <Typography>{parse(question.questionDescription)}</Typography>
          </div>
        </Grid>
      )}
      {subQuestions.length > 0 &&
        subQuestions.map((subQuestion) => {
          return subQuestion.questionId === question.questionId ? (
            <SubQuestionDetails
              parentCallback={callback}
              dateCallback={dateCallback}
              isSubmitted={props.isSubmitted}
              isDrafted={props.isDrafted}
              key={subQuestion.subQuestionId}
              question={question}
              subQuestion={subQuestion}
              formCompleted={props.formCompleted}
              answer={
                props.answers && props.answers.filter((answer) => answer.subQuestionId === subQuestion.subQuestionId)
              }
            />
          ) : null;
        })}
    </React.Fragment>
  );
};

export default QuestionDetails;
