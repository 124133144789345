import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button, Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SubQuestionDetails from "./SubQuestionDetails";
import CommentInputs from "./CommentInputs";
import { useHistory } from "react-router-dom";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import { getAccessToken, checkIfRoleExists } from "../../../../../utils/oauth";

var parse = require("html-react-parser");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  descriptionParent: {
    display: "flex",
  },

  descriptionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  descriptionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "95%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400p6x)": {
      flexBasis: "85%",
    },
  },
  buttonGrid: {
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: "4.3%",
    "@media(max-width: 900px)": {
      paddingLeft: "5%",
    },
    "@media(max-width: 600px)": {
      paddingLeft: "10%",
    },
    "@media(max-width: 400px)": {
      paddingLeft: "15%",
    },
  },
  saveButton: {
    textTransform: "none",
  },
  editButton: {
    left: 15,
    textTransform: "none",
  },
  viewTRButton: {
    marginBottom: 10,
    marginTop: 10,
    textTransform: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const QuestionDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const question = props.question;
  const subQuestions = props.subQuestions;
  const category = props.category;
  const files = props.files;

  const [backdropOpen, setbackDrop] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [errorSnack, setErrorSnack] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isError, setError] = useState(false);
  const [financeResult, setFinanceResult] = useState("");
  const [isAuthorized, setAuthorized] = useState(true);

  useEffect(() => {
    if ((question.questionId === 40 || question.questionId === 41) && checkIfRoleExists(roles.superuser)) {
      setAuthorized(true);
    } else if (question.questionId === 44 && checkIfRoleExists(roles.financeApprover)) {
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  });

  const callback = (subQuestionId, booleanAnswer, descriptionAnswer, uploadFiles, isValid) => {
    if (booleanAnswer === true) {
      setFinanceResult("approved");
    } else if (booleanAnswer === false) {
      setFinanceResult("rejected");
    }

    setIsValid(isValid);

    setAnswers(answers.filter((item) => item.subQuestionId !== subQuestionId));

    if (isValid) {
      setError(false);
      setAnswers((prev) => [
        ...prev,
        {
          subQuestionId: subQuestionId,
          questionId: question.questionId,
          booleanAnswer: Boolean(booleanAnswer),
          descriptionAnswer: descriptionAnswer,
          companyId: props.companyId,
        },
      ]);
    }
  };

  const saveData = async () => {
    if (isValid) {
      setbackDrop(true);
      setFieldDisabled(true);
      const requestURL = AppConfig.baseUrl + AppConfig.financeFeedback;

      const data = {
        answers: answers,
        financeResult: financeResult,
      };

      try {
        let response = await fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAccessToken(),
          },
          body: JSON.stringify(data),
        });
        let body = await response.json();
        if (response.status === 200) {
          setSuccess(true);
          setIsValid(false);
          props.formRender();
        } else {
          console.error(body.message);
          setErrorSnack(true);
        }
        setbackDrop(false);
        return null;
      } catch (error) {
        setbackDrop(false);
        console.error(error);
        return error;
      }
    } else {
      setError(true);
    }
  };

  const editField = () => {
    setFieldDisabled(false);
  };

  const viewTradeReference = async (index) => {
    setbackDrop(true);
    const requestURL = AppConfig.baseUrl + AppConfig.tradeReferenceData + props.companyId + "/" + AppConfig.linkData;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();

      if (response.status === 200) {
        if (body && body.length > 0) {
          if (body[index - 1] && body[index - 1].linkId) {
            setbackDrop(false);
            history.push({
              pathname:
                AppConfig.urls.ADMIN +
                AppConfig.urls.TRADE_REFERENCE_FORM +
                props.companyId +
                "/" +
                body[index - 1].linkId,
            });
          }
        } else {
          setbackDrop(false);
          setErrorSnack(true);
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }

      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setErrorSnack(false);
  };

  const disableCall = (isDisable, answer) => {
    if (answer.length > 0 || props.answers.some((answer) => answer.questionId === 44)) {
      setFieldDisabled(true);
    } else {
      setFieldDisabled(isDisable);
    }
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Failed!
        </Alert>
      </Snackbar>

      {category.categoryId === question.categoryId && (
        <React.Fragment>
          <Grid item xs={12}>
            <div className={classes.descriptionParent}>
              <Typography className={classes.descriptionIndex}></Typography>
              <Typography variant="body2" className={classes.descriptionDetails}>
                {parse(question.questionHeading)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.verticalcenter}>
            <div className={classes.descriptionParent}>
              <Typography className={classes.descriptionIndex}>
                {category.sortIndex - 2}.{question.sortIndex}
              </Typography>
              <Typography className={classes.descriptionDetails}>{parse(question.questionDescription)}</Typography>
            </div>
            {category.categoryId === 3 && props.selectedRadio === "references" && (
              <div className={classes.descriptionParent}>
                <Typography className={classes.descriptionIndex}></Typography>
                <div className={classes.descriptionDetails}>
                  <Button
                    className={classes.viewTRButton}
                    variant="outlined"
                    color="primary"
                    disabled={!fieldDisabled}
                    size="small"
                    onClick={() => viewTradeReference(question.sortIndex)}
                  >
                    View Trade Reference{" "}
                  </Button>
                </div>
              </div>
            )}
          </Grid>
          {subQuestions.length > 0 &&
            subQuestions.map((subQuestion) => {
              return subQuestion.questionId === question.questionId ? (
                <SubQuestionDetails
                  key={subQuestion.subQuestionId}
                  category={category}
                  question={question}
                  subQuestion={subQuestion}
                  files={files}
                  parentCallback={callback}
                  formCompleted={props.formCompleted}
                  isSubmitted={props.isSubmitted}
                  isDrafted={props.isDrafted}
                  disableCall={disableCall}
                  isError={isError}
                  fieldDisabled={isAuthorized ? fieldDisabled : true}
                  answer={
                    props.answers &&
                    props.answers.filter((answer) => answer.subQuestionId === subQuestion.subQuestionId)
                  }
                />
              ) : null;
            })}
          {(question.questionId === 42 || question.questionId === 43) && (
            <Grid item xs={12}>
              <CommentInputs
                questionId={question.questionId}
                companyId={props.companyId}
                comments={props.comments}
                formRender={props.formRender}
              />
            </Grid>
          )}
          {(category.categoryId === 7 || category.categoryId === 8) &&
            question.questionDescription !== "Creator" &&
            question.questionDescription !== "Reviewer" &&
            isAuthorized && (
              <div className={classes.buttonGrid}>
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="secondary"
                  disabled={fieldDisabled || !isValid}
                  size="small"
                  onClick={saveData}
                >
                  Save
                </Button>
                <Button
                  className={classes.editButton}
                  variant="outlined"
                  color="primary"
                  disabled={!fieldDisabled}
                  size="small"
                  onClick={editField}
                >
                  Edit
                </Button>
              </div>
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default QuestionDetails;
