/* eslint-disable no-use-before-define */
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { getAccessToken } from "../../../utils/oauth";
import { AppConfig } from "../../../configs/admin-config";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CountrySelect(props) {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);

  const getCountriesList = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.countries;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });

      let data = await response.json();

      if (response.status === 200 && data.length > 0) {
        setCountries(data);
      } else {
        console.warn("No countries retrieved");
      }
    } catch (error) {
      console.error("Failed to fetch countries: ", error);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {}, [props.region]);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="region-select-label">Country</InputLabel>
      <Select
        labelId="region-select-label"
        id="region-select"
        label="Region"
        value={props.region}
        onChange={props.handleChange}
      >
        {countries.map((country) => (
          <MenuItem key={country.countryId} value={country.countryId}>
            {country.countryName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
