import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import { Box, Card, CardContent, Container, Divider, Grid, Typography } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuthContext } from "@asgardeo/auth-react";
import {
  setIdToken,
  setUserName,
  setUserRoles,
  setRefreshTokenFunction,
  setSessionClearFunction,
  setAccessToken,
} from "../src/utils/oauth";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "././theme/theme";
import Admin from "./components/admin-app/Admin";
import Client from "./components/client-app/Client";
import { createBrowserHistory } from "history";
import { APP_NAME, APP_REDIRECT_URL_KEY, AppConfig, roles } from "../src/configs/admin-config";
import "./App.css";

function App() {
  const {
    state,
    signIn,
    signOut,
    getBasicUserInfo,
    getIDToken,
    getDecodedIDToken,
    refreshAccessToken,
    getAccessToken,
    revokeAccessToken,
  } = useAuthContext();
  const [loadApp, setLoadApp] = useState(false);
  const [isAuthorised, setAuthorised] = useState(false);
  const [snackbarData, setSnackbarData] = useState({ open: false, message: "", severity: "", onClose: null });
  const [isAppInitializing, setIsAppInitializing] = useState(false);
  const history = useHistory();
  const browserHistory = createBrowserHistory();
  const isAdminPath = browserHistory.location.pathname.includes("/admin");
  const isClientPath = browserHistory.location.pathname.includes("/client");

  const getIsInitLogin = () => {
    if (sessionStorage.getItem("isInitLogin") === "true") {
      return true;
    }
    return false;
  };

  const setIsInitLogin = (value) => {
    sessionStorage.setItem("isInitLogin", value);
  };

  const getIsLoggedOut = () => {
    if (sessionStorage.getItem("isLoggedOut") === "true") {
      return true;
    }
    return false;
  };

  const setIsLoggedOut = (value) => {
    sessionStorage.setItem("isLoggedOut", value);
    setLoggedOutState(value === "true");
  };

  const [loggedOutState, setLoggedOutState] = useState(getIsLoggedOut());

  const getIsSessionTimeOut = () => {
    if (sessionStorage.getItem("isSessionTimeOut") === "true") {
      return true;
    }
    return false;
  };

  const setIsSessionTimeOut = (value) => {
    sessionStorage.setItem("isSessionTimeOut", value);
  };

  const handleLogin = () => {
    setIsInitLogin("true");
    setIsSessionTimeOut("false");
    setIsLoggedOut("false");

    signIn().catch((e) => {
      console.error(e);
    });
  };

  const handleLogout = () => {
    setIsAppInitializing(true);
    setIsInitLogin("false");
    setIsLoggedOut("true");
    setIsSessionTimeOut("false");
    setIsAppInitializing(false);
    revokeAccessToken().then(signOut());
  };

  const handleTokenRefresh = async () => {
    return refreshAccessToken()
      .then(async (e) => {
        setAccessToken(await getAccessToken());
      })
      .catch((err) => {
        if (err) {
          throw err;
        }
      });
  };

  const sessionClearFn = () => {
    setLoadApp(false);
    setIsInitLogin("false");
    setIsSessionTimeOut("true");
    setIsLoggedOut("true");

    revokeAccessToken().catch((e) => {
      console.error(e);
    });
    history && history.push("");
  };

  const onCloseSnackbar = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  useEffect(() => {
    if (!getIsLoggedOut() && !(state?.isLoading || state?.isAuthenticated) && isAdminPath) {
      if (!localStorage.getItem(APP_REDIRECT_URL_KEY)) {
        localStorage.setItem(APP_REDIRECT_URL_KEY, window.location.pathname + window.location.search);
      }
      handleLogin();
    }
  }, [state.isLoading, state.isAuthenticated]);

  useEffect(() => {
    if (state && state.isAuthenticated) {
      if (localStorage.getItem(APP_REDIRECT_URL_KEY)) {
        browserHistory.push(localStorage.getItem(APP_REDIRECT_URL_KEY));
        localStorage.removeItem(APP_REDIRECT_URL_KEY);
      }

      setRefreshTokenFunction(handleTokenRefresh);
      setSessionClearFunction(sessionClearFn);

      const getData = async (callback) => {
        const basicUserInfo = await getBasicUserInfo();
        const accessToken = await getAccessToken();
        const idToken = await getIDToken();
        const decodedIDToken = await getDecodedIDToken();

        setIdToken(idToken);
        setAccessToken(accessToken);

        if (accessToken) {
          setLoadApp(true);
          callback?.();
        }

        if (basicUserInfo?.email) {
          setUserName(basicUserInfo.email);
        }
        if (basicUserInfo?.groups) {
          setUserRoles(basicUserInfo.groups);
        }

        const roleList = Object.values(roles);
        basicUserInfo.groups.forEach((role) => {
          roleList.forEach((appRoles) => {
            if (role === appRoles) {
              setAuthorised(true);
            }
          });
        });
      };
      getData();
    }
  }, [state.isAuthenticated, state.isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
            {isAdminPath ? (
              <div>
                {state.isAuthenticated && loadApp ? (
                  <Route path="/admin">
                    <Admin isAuthorised={isAuthorised} handleLogout={handleLogout} />
                  </Route>
                ) : (
                  <div>
                    <title>Login | {APP_NAME}</title>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                      <Container maxWidth="md">
                        <Card>
                          <CardContent>
                            <Box
                              sx={{
                                p: 2,
                              }}
                            >
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12}>
                                  <img
                                    alt="logo"
                                    width="150"
                                    height="auto"
                                    src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
                                  ></img>
                                </Grid>
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                  <Typography variant="h2">{APP_NAME}</Typography>
                                </Grid>
                                {/* Handle Error authenticationError */}
                                <Grid item xs={12}>
                                  <LoadingButton
                                    id="login"
                                    onClick={() => {
                                      handleLogin();
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    loading={getIsInitLogin()}
                                    loadingPosition="center"
                                  >
                                    Log In
                                  </LoadingButton>
                                </Grid>
                                {getIsInitLogin() && (
                                  <Grid item xs={12}>
                                    <Typography variant="caption">Please wait</Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          </CardContent>
                          <Divider />
                        </Card>
                      </Container>
                    </Box>
                  </div>
                )}
              </div>
            ) : (
              <Fragment>
                {isClientPath ? (
                  <Route path="/client">
                    <Client />
                  </Route>
                ) : (
                  !(browserHistory.location.search && browserHistory.location.search.length > 0) && (
                    <Redirect
                      exact
                      from="/"
                      to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + AppConfig.defaultTabName}
                    />
                  )
                )}
              </Fragment>
            )}
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
