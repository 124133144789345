import { useState, useEffect } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import {
  Container,
  Chip,
  Grid,
  Button,
  Typography,
  Backdrop,
  Snackbar,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig, roles } from "../../../../configs/admin-config";
import DoneIcon from "@material-ui/icons/Done";
import { useHistory } from "react-router-dom";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useHttp from "../../../../utils/http";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog";
import { getAccessToken, checkIfRoleExists } from "../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  formStatusGrid: {
    marginTop: 88,
  },
  buttonGrid: {
    textAlign: "end",
  },
  button: {
    textTransform: "none",
  },
  Typography: {
    marginTop: 50,
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "start",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PendingPage = (props) => {
  const history = useHistory();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const classes = useStyles();
  const [copySnack, setCopy] = useState(false);
  const [backdropOpen, setbackDrop] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const [linkData, setLinkData] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(null);

  useEffect(() => {
    if (!checkIfRoleExists(roles.finance) || !props.location.state) {
      history.replace({
        pathname: AppConfig.urls.NO_ACCESS_PAGE,
      });
    }
    getResellerData();
  }, []);

  const copyURL = (e) => {
    e.stopPropagation();
    const externalUrl = AppConfig.externalUrl;

    const linkURL = externalUrl + "resellerform/validate/" + props.location.state.item.encode_string;

    let el = document.createElement("textarea");
    el.value = linkURL;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopy(true);
  };

  const handleChangeExpiry = (shouldExpire) => () => {
    handleConfirmationDialog({
      title: "You are about to make a change to a reseller!",
      text: "Are you sure you want to " + (shouldExpire ? "enable expiry" : "disable expiry") + "?",
      confirmAction: () => {
        changeExpiry(shouldExpire);
      },
    });
  };

  const getResellerData = () => {
    if (props.match.params.id) {
      sendRequest(
        AppConfig.baseUrl + AppConfig.partnerData + props.match.params.id,
        "GET",
        null,
        AppConfig.partnerData
      );
    }
  };

  const changeExpiry = (shouldExpire) => {
    if (props.match.params.id) {
      var payload = {
        linkId: props.match.params.id.toString(),
        shouldExpire: shouldExpire,
      };
      sendRequest(
        AppConfig.baseUrl + AppConfig.changeResellerExpiry,
        "POST",
        JSON.stringify(payload),
        AppConfig.changeResellerExpiry
      );
    }
  };

  const handleConfirmationDialog = (content) => {
    setConfirmationDialog(content);
  };

  const handleChangeStatus = (status) => () => {
    handleConfirmationDialog({
      title: "You are about to make a change to a reseller!",
      text: "Are you sure you want to " + (status === "active" ? "activate" : "deactivate") + " this reseller?",
      confirmAction: () => {
        changeStatus(status);
      },
    });
  };

  const changeStatus = async (status) => {
    setbackDrop(true);

    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.match.params.id + "/" + AppConfig.linkStatus;

    let data = {
      status: status,
      linkId: parseInt(props.match.params.id),
    };
    try {
      let response = await fetch(requestURL, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      setbackDrop(false);
      if (response.status === 200) {
        setSuccess(true);
        props.history.goBack();
      } else {
        console.error(body.message);
        setFail(true);
      }
      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setCopy(false);
  };

  const handleGoBack = () => {
    history.push(AppConfig.urls.LANDING_PAGE);
  };

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.getResellerData:
        if (data) {
          if (data && !error) {
            if (data.companyName) {
              setCompanyData(data.companyName);
            }
            if (data.link) {
              setLinkData(data.linkId);
            }
          } else {
            console.warn("Not retrieved any data from backend");
          }
        } else if (error) {
          console.warn("Not retrieved any data from backend");
        }
        setbackDrop(false);
        break;
      case AppConfig.changeResellerExpiry:
        if (data && !error) {
          getResellerData();
        } else if (error) {
          console.warn("Not retrieved any data from backend");
        }
        setbackDrop(false);
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  return (
    <>
      <Container>
        <Backdrop open={backdropOpen} className={classes.backdrop}>
          <CircularProgress color="secondary" />
        </Backdrop>

        <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Success!
          </Alert>
        </Snackbar>
        <Snackbar open={copySnack} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Copied to Clipboard!
          </Alert>
        </Snackbar>
        <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Server Error!
          </Alert>
        </Snackbar>
        <Grid container spacing={1} className={classes.formStatusGrid}>
          <Grid item xs={12}>
            <Grid container direction="row" justifycontent="flex-start" alignItems="center">
              <Grid item>
                <NavLink to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE}>
                  <IconButton aria-label="back">
                    <ArrowBackIcon />
                  </IconButton>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={6} lg={1}>
            <Chip
              variant="outlined"
              label={"Form Status: Pending " + (linkData?.isExpired ? "(Expired)" : "")}
              color={"primary"}
            />
          </Grid>

          <Grid item xs={5} md={6} lg={6} className={classes.buttonGrid}>
            {linkData?.isExpiring !== undefined ? (
              Boolean(linkData?.isExpiring) ? (
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={handleChangeExpiry(false)}
                >
                  Disable Expiry
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={handleChangeExpiry(true)}
                >
                  Enable Expiry
                </Button>
              )
            ) : null}
          </Grid>

          {checkIfRoleExists(roles.admin) && (
            <Grid item xs={2} md={10} lg={10} className={classes.buttonGrid}>
              {props.location.state.item.status === "active" && (
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={handleChangeStatus("inactive")}
                >
                  Deactivate
                </Button>
              )}

              {props.location.state.item.status === "inactive" && (
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={handleChangeStatus("active")}
                >
                  Activate
                </Button>
              )}
            </Grid>
          )}

          {checkIfRoleExists(roles.admin) && (
            <Grid item xs={12} md={2} lg={2} className={classes.buttonGrid}>
              <Button className={classes.button} variant="outlined" color="primary" startIcon={<DoneIcon />} disabled>
                Editing Allowed
              </Button>
            </Grid>
          )}
        </Grid>

        <Typography className={classes.Typography}>
          {props.location.state && props.location.state.item.companyName} has not still filled the form
        </Typography>
      </Container>
      {confirmationDialog && <ConfirmationDialog content={confirmationDialog} handleOpen={handleConfirmationDialog} />}
    </>
  );
};

export default PendingPage;
