import React from "react";
import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "./wso2-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.dark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoSpan: {
    width: 80,
    marginRight: 20,
  },
}));

const ClientHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <span className={classes.logoSpan}>
          <img src={logo} alt="Logo" style={{ width: "100%" }} />
        </span>
        <Typography variant="h6" className={classes.title}>
          Partner App
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default ClientHeader;
