import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import imageCompression from "browser-image-compression";
import useHttp from "../../utils/http";
import { Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import watermark from "watermarkjs";
import { Visibility as VisibilityIcon, Delete as DeleteIcon, Backup as UploadIcon } from "@material-ui/icons";
import { AppConfig } from "../../configs/admin-config";

const useStyles = makeStyles((theme) => ({
  preview: {
    margin: "auto",
    display: "block",
    objectFit: "cover",
    width: "100%",
    maxHeight: 250,
  },
  imageUpload: {
    padding: 0,
  },
  image: {
    minHeight: 200,
    border: "1px solid #ccc",
    borderRadius: 4,
  },
  marginTop20: {
    marginTop: 20,
  },
}));

const Item = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SignatureUpload = (props) => {
  const classes = useStyles();
  const [imageName, setImageName] = useState(null);
  const [imageProgress, setImageProgress] = useState(0);
  const [compressedImage, setCompressedImage] = useState(null);
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [url, setUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleOpenImagePreview = () => () => {
    handleImagePreview(true);
  };

  const handleImagePreview = (isOpen) => {
    setImagePreview(
      isOpen
        ? {
            title: "Preview",
            url: url,
          }
        : null
    );
  };

  const getImage = (fileName) => {
    if (fileName) {
      // sendRequestForImage(AppConfig.IMAGE_URL + "/" + fileName, 'GET', null, AppConfig.IMAGE_URL);
    }
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 4,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "jpeg",
      onProgress: (progress) => {
        setImageProgress(progress <= 25 ? 25 : progress <= 50 ? 50 : progress);
        if (progress === 100) {
          setTimeout(() => {
            setImageProgress(0);
          }, 1000);
        }
      },
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setCompressedImage(URL.createObjectURL(compressedFile));
      setImageName(imageName);
      watermark([imageFile])
        .image(watermark.text.center("WSO2 PVT LTD", "72px Arial", "#aaa", 1))
        .then((img) => {
          props.handleSignature(img.src);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function handleImageDelete(event) {
    setCompressedImage(null);
    props.handleSignature(null);
  }

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.signatureUpload:
        if (data) {
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    getImage(props.imageName);
  }, [props.compressedImage, props.imageName, props.imageProgress]);
  useEffect(() => {}, [props.readOnly]);

  return (
    <>
      <Grid
        style={{ minWidth: 300, maxWidth: 300 }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.image}
      >
        {imageName || compressedImage ? (
          <Grid container>
            <Grid item xs={12}>
              <img
                className={classes.preview}
                alt={"signature"}
                src={url ? url : imageProgress === 0 ? compressedImage : null}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center !important" }}>
              <Grid direction="row" justifyContent="center" alignItems="center" spacing={1}>
                {/* <span>
                                    <Tooltip title="View">
                                        <IconButton aria-label="view"
                                            onClick={handleOpenImagePreview()}
                                            size="small">
                                            <VisibilityIcon size="20px" />
                                        </IconButton>
                                    </Tooltip>
                                </span> */}
                {compressedImage ? (
                  <span>
                    <Tooltip title="Delete">
                      <IconButton aria-label="delete" onClick={handleImageDelete} size="small">
                        <DeleteIcon size="20px" />
                      </IconButton>
                    </Tooltip>
                  </span>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        ) : props.readOnly ? (
          // <PreviewNotAvailable />
          <>{"PreviewNotAvailable"}</>
        ) : (
          <Grid item className={classes.imageUpload}>
            {imageProgress === 0 ? (
              <Grid item>
                <Item>
                  <input
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    id="contained-button-file"
                    multiple
                    onChange={handleImageUpload}
                    type="file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" startIcon={<UploadIcon />}>
                      Upload Signature
                    </Button>
                  </label>
                </Item>
                <Item className={`${classes.marginTop20}`}>
                  <Typography variant="caption">A watermark will be added to your signature</Typography>
                  <br />
                  <Typography variant="caption">Recommended image file size is below 10 MB.</Typography>
                  <br />
                  <Typography variant="caption">Image should be in 'Landscape' orientation.</Typography>
                </Item>
              </Grid>
            ) : (
              <CircularProgress variant="determinate" value={props.imageProgress} />
            )}
          </Grid>
        )}
      </Grid>
      {/* {imagePreview && <ImagePreview imagePreview={imagePreview} handleImagePreview={handleImagePreview} />} */}
    </>
  );
};

export default SignatureUpload;
