import React, { useEffect, useState } from "react";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import {
  Container,
  Chip,
  Grid,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { getAccessToken, checkIfRoleExists } from "../../../../../utils/oauth";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    padding: "10px 60px",
  },
  backgroundColor: {
    minHeight: "800px",
  },
  container: {
    flexGrow: 1,
  },
  justifyText: {
    textAlign: "justify",
  },
  button: {
    fontSize: 12,
    textTransform: "none",
  },
}));

const Approval = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [backdropOpen, setbackDrop] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [financeResult, setFinanceResult] = useState(null);
  const [legalResult, setLegalResult] = useState(null);
  const [resellerLink, setResellerLink] = useState(null);
  const [formRender, setFormRender] = useState(0);

  const getApproveSummary = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.companyId + "/" + AppConfig.approvalSummary;
    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          if (body.summary.length > 0) {
            setFinanceResult(body.summary[0].financeResult);
            setLegalResult(body.summary[0].legalResult);
          } else {
            history.push({
              pathname: AppConfig.urls.NOT_FOUND_PAGE,
            });
          }

          if (body.resellerLinks.length > 0) {
            setResellerLink(body.resellerLinks[0]);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    setbackDrop(true);
    getApproveSummary();
  }, [formRender]);

  const changeState = async (state) => {
    setDialogOpen(false);
    setDialogOpen2(false);
    setbackDrop(true);

    const requestURL = AppConfig.baseUrl + AppConfig.partnerData + resellerLink.linkId + "/" + AppConfig.linkStatus;

    let data = {
      linkId: resellerLink.linkId,
      status: state,
    };

    try {
      let response = await fetch(requestURL, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      setbackDrop(false);
      if (response.status === 200) {
        setSuccess(true);
        setFormRender(formRender + 1);
      } else {
        console.error(body.message);
        setFail(true);
      }
      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };
  const openDialog2 = () => {
    setDialogOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogOpen2(false);
  };

  return (
    <div>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{"Do you want to deactivate this company?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This company will no longer a be partner of WSO2. Are you sure you want to do this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => changeState("inactive")} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen2} onClose={handleCloseDialog}>
        <DialogTitle>{"Do you want to activate this company?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>This company will become a partner of WSO2. Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => changeState("active")} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Container className={classes.root}>
        <br></br>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {financeResult && (
              <Grid container spacing={1}>
                <Grid item xs={6} md={3}>
                  Finance Approval
                </Grid>
                <Grid item xs={6} md={4}>
                  {financeResult === "pending" ? (
                    <Chip icon={<MoreHorizIcon />} label={financeResult} variant="outlined" color="primary" />
                  ) : (
                    <Chip
                      icon={financeResult === "approved" ? <DoneIcon /> : <CloseIcon />}
                      variant="outlined"
                      label={financeResult}
                      color={financeResult === "rejected" ? "secondary" : "primary"}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {legalResult && (
              <Grid container spacing={1}>
                <Grid item xs={6} md={3}>
                  Legal Approval
                </Grid>
                <Grid item xs={6} md={4}>
                  {legalResult === "pending" ? (
                    <Chip icon={<MoreHorizIcon />} label={legalResult} variant="outlined" color="primary" />
                  ) : (
                    <Chip
                      icon={legalResult === "approved" ? <DoneIcon /> : <CloseIcon />}
                      variant="outlined"
                      label={legalResult}
                      color={legalResult === "rejected" ? "secondary" : "primary"}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div>
          <br></br>
          <hr></hr>
          <br></br>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {resellerLink && (
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12} md={3}>
                  Company Status
                </Grid>
                <Grid item sm={4} xs={12} md={3}>
                  {resellerLink.status === "active" ? (
                    <Chip icon={<DoneIcon />} label={resellerLink.status} variant="outlined" color="primary" />
                  ) : (
                    <Chip icon={<CloseIcon />} variant="outlined" label={resellerLink.status} color="secondary" />
                  )}
                </Grid>
                <Grid item sm={4} xs={12} md={4}>
                  {resellerLink && checkIfRoleExists(roles.admin) && (
                    <div>
                      {resellerLink.status === "active" ? (
                        <Button className={classes.button} variant="contained" color="secondary" onClick={openDialog}>
                          Deactivate
                        </Button>
                      ) : (
                        <Button className={classes.button} variant="contained" color="primary" onClick={openDialog2}>
                          Activate
                        </Button>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Approval;
