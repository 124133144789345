import { useEffect, useState } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import { Container, Chip, Grid, Button, IconButton, Typography, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig, roles } from "../../../../configs/admin-config";
import { checkIfRoleExists } from "../../../../utils/oauth";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  formStatusGrid: {
    marginTop: 88,
  },
  buttonGrid: {
    textAlign: "end",
  },
  button: {
    textTransform: "none",
  },
  Typography: {
    marginTop: 50,
    textAlign: "center",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PendingPage2 = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [copySnack, setCopy] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);

  useEffect(() => {
    if (!checkIfRoleExists(roles.finance) && !props.location.state) {
      history.replace({
        pathname: AppConfig.urls.NO_ACCESS_PAGE,
      });
    }
  }, []);

  const copyURL = (e) => {
    e.stopPropagation();
    const externalUrl = AppConfig.externalUrl;
    const linkURL = externalUrl + "tradereferenceform/validate/" + props.location.state.encodeString;
    let el = document.createElement("textarea");
    el.value = linkURL;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopy(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setCopy(false);
  };

  return (
    <Container>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={copySnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Copied to Clipboard!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>

      <Grid container spacing={1} className={classes.formStatusGrid}>
        <Grid item xs={12}>
          <Grid container direction="row" justifycontent="flex-start" alignItems="center">
            <Grid item>
              <NavLink to={AppConfig.urls.ADMIN + "home/tradereferences"}>
                <IconButton aria-label="back">
                  <ArrowBackIcon />
                </IconButton>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={8} container direction="row" justifycontent="flex-start" alignItems="center">
          <Chip variant="outlined" label={"Form Status: Pending"} color={"primary"} />
        </Grid>
        {(checkIfRoleExists(roles.admin) || checkIfRoleExists(roles.channelManager)) && (
          <Grid item xs={12} md={3} lg={4} className={classes.buttonGrid}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={copyURL}
              startIcon={<LinkOutlinedIcon />}
            >
              Generate Form URL
            </Button>
          </Grid>
        )}
      </Grid>

      <Typography className={classes.Typography}>
        {props.location.state && props.location.state.item.companyName} has not still filled the form
      </Typography>
    </Container>
  );
};

export default PendingPage2;
