import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import {
  AppBar,
  Grid,
  Avatar,
  Button,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import logo from "../assets/wso2-logo.png";
import { AppConfig } from "../configs/admin-config";
import { getAccessToken, getUserName } from "../utils/oauth";
import useHttp from "../utils/http";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.dark,
    minHeight: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoSpan: {
    width: 80,
    marginRight: 20,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();
  const [userName, setUserName] = useState("");
  const [imageURL, setImageURL] = useState("");
  const anchorRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const preventDefault = (event) => event.preventDefault();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogout = (event) => {
    props.handleLogout();
    handleCloseMenu(event);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const getUser = async () => {
    let userEmail = getUserName();

    const requestURL = AppConfig.userDataURL;
    let data = {
      select_employee_operation: {
        email: userEmail,
      },
    };

    try {
      let response = await fetch(requestURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });
      let body = await response.json();
      if (body.Entries) {
        if (body.Entries["Entry"].length > 0) {
          let user = body.Entries["Entry"][0];
          if (user["FirstName"] && user["LastName"]) {
            setUserName(user["FirstName"] + " " + user["LastName"]);
          }
          if (user["ThumbnailURL"]) {
            setImageURL(user["ThumbnailURL"]);
          }
        }
      }

      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.getResellerQuestionData:
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar variant="dense">
        <span className={classes.logoSpan}>
          <NavLink to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE}>
            <img src={logo} alt="Logo" style={{ width: "100%" }} />
          </NavLink>
        </span>

        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid iem>
            <Typography variant="h6" noWrap>
              Due Diligence App
            </Typography>
          </Grid>

          <Grid item></Grid>

          <Grid item xs={5}>
            <Grid container direction="row" spacing={1} justify="flex-end" alignItems="center">
              <Grid item>
                <Typography variant="body2">{userName ? userName : getUserName()}</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  color="inherit"
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  {imageURL ? (
                    <Avatar className={classes.avatar} alt={userName ? userName : getUserName()} src={imageURL} />
                  ) : (
                    <AccountCircle></AccountCircle>
                  )}
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
