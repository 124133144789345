import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  Chip,
  Button,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAlert from "@material-ui/lab/Alert";
import { AppConfig, roles } from "../../../../configs/admin-config";
import { checkIfRoleExists } from "../../../../utils/oauth";
import { NavLink, useHistory } from "react-router-dom";
import QuestionDetails from "./QuestionDetails";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import useHttp from "../../../../utils/http";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  backgroundColor: {
    backgroundColor: "#F7F7F7",
    minHeight: "100vh",
    marginTop: 48,
  },
  container: {
    flexGrow: 1,
    paddingBottom: 30,
    paddingTop: 30,
  },
  formStatusGrid: {
    marginBottom: 20,
  },
  justifyText: {
    textAlign: "justify",
  },
  accordionSummary: {
    padding: "0% 4%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  headingName: {
    textAlign: "justify",
    fontWeight: "bold",
  },
  accordionDetails: {
    padding: "1.5% 4%",
    paddingBottom: 30,
  },
  button: {
    backgroundColor: "white",
    textTransform: "none",
  },
  buttonGrid: {
    textAlign: "end",
  },
}));

const TradeReferenceDashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen } = useHttp();

  const [backdropOpen, setbackDrop] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setExpanded] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [resellerData, setResellerData] = useState([]);
  const [successSnack, setSuccess] = useState(false);
  const [copySnack, setCopy] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [linkData, setLinkData] = useState(null);
  const [formRender, setFormRender] = useState(0);

  const getResellerData = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.getResellerDataByCompanyId + props.match.params.companyId;
    sendRequest(requestURL, "GET", null, AppConfig.getResellerDataByCompanyId);
  };

  const getTradeReferenceData = async () => {
    const requestURL =
      AppConfig.baseUrl + AppConfig.getTradeReferences + props.match.params.companyId + "/" + props.match.params.linkId;
    sendRequest(requestURL, "GET", null, AppConfig.getTradeReferenceData);
  };

  const getTradeReferenceInfoQuestions = () => {
    const requestURL = AppConfig.baseUrl + AppConfig.getTradeReferenceQuestionData;
    sendRequest(requestURL, "GET", null, AppConfig.getTradeReferenceQuestionData);
  };

  useEffect(() => {
    if (checkIfRoleExists(roles.finance)) {
      setbackDrop(true);
      getResellerData();
    } else {
      history.replace({
        pathname: AppConfig.urls.NO_ACCESS_PAGE,
      });
    }
  }, [formRender]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const copyURL = (e) => {
    e.stopPropagation();
    const externalUrl = AppConfig.externalUrl;

    const linkURL = externalUrl + "tradereferenceform/validate/" + props.location.state.encodeString;

    let el = document.createElement("textarea");
    el.value = linkURL;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopy(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setCopy(false);
  };

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.getResellerDataByCompanyId:
        if (data && data.length > 0) {
          setResellerData(data[0]);
        } else {
          console.warn("Not retrieved any data from backend");
        }
        getTradeReferenceData();
        break;
      case AppConfig.getTradeReferenceData:
        if (data && !error) {
          if (data.data) {
            setAnswers(data.data);
            getTradeReferenceInfoQuestions();
          }
          if (data.linkData) {
            setLinkData(data.linkData[0]);
          }
          setLoading(false);
          setbackDrop(false);
        } else {
          setLoading(false);
          setbackDrop(false);
          console.warn("Not retrieved link data from backend");
        }
        break;
      case AppConfig.getTradeReferenceQuestionData:
        if (data && !error) {
          if (data.questions) {
            setQuestions(data.questions);
          }
          if (data.subQuestions) {
            setSubQuestions(data.subQuestions);
          }
          setLoading(false);
          setbackDrop(false);
        } else if (error) {
          setLoading(false);
          setbackDrop(false);
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={copySnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Copied to Clipboard!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Failed!
        </Alert>
      </Snackbar>
      {linkData?.status && (
        <div className={classes.backgroundColor}>
          <Container className={classes.container}>
            <Grid container spacing={1} className={classes.formStatusGrid}>
              <Grid item xs={12}>
                <Grid container direction="row" justifycontent="flex-start" alignItems="center">
                  <Grid item>
                    <NavLink to={AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + AppConfig.urls.TRADE_REFERENCES}>
                      <IconButton aria-label="back">
                        <ArrowBackIcon />
                      </IconButton>
                    </NavLink>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={8}
                container
                direction="row"
                justifycontent="flex-start"
                alignItems="center"
              >
                <Chip
                  variant="outlined"
                  label={
                    linkData.status === "completed"
                      ? "Form Status : Completed"
                      : linkData.status === "pending"
                      ? "Form Status : Requested to Edit"
                      : linkData.status === "drafted"
                      ? "Form Status : Drafted"
                      : "Form Status : Pending"
                  }
                  color={"primary"}
                />
              </Grid>
              {(checkIfRoleExists(roles.admin) || checkIfRoleExists(roles.channelManager)) && (
                <Grid item xs={12} md={3} lg={4} className={classes.buttonGrid}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={copyURL}
                    startIcon={<LinkOutlinedIcon />}
                  >
                    Generate Form URL
                  </Button>
                </Grid>
              )}
            </Grid>

            <div>
              <Paper className={classes.paper}>
                <Accordion
                  className={classes.accordion}
                  expanded={isExpanded}
                  onChange={() => {
                    setExpanded(!isExpanded);
                  }}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.headingName}>Trade Reference Information</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div>
                          <Typography style={{ textAlign: "justify" }} variant="body2">
                            Regarding
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          defaultValue={resellerData && resellerData.companyName}
                          disabled
                          size="small"
                          label="Reference Company Name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          defaultValue={resellerData && resellerData.country}
                          disabled
                          size="small"
                          label="Country"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      {resellerData && resellerData.state !== "" && (
                        <Grid item xs={12} lg={6}>
                          <TextField
                            defaultValue={resellerData && resellerData.state}
                            disabled
                            size="small"
                            label="State"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} lg={6}>
                        <TextField
                          defaultValue={resellerData && resellerData.cityProvince}
                          disabled
                          size="small"
                          label="City/Province"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          defaultValue={resellerData && resellerData.streetAddress}
                          disabled
                          size="small"
                          label="Street Address"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      {questions.length > 0 &&
                        subQuestions.length > 0 &&
                        questions.map((question) => {
                          return (
                            <QuestionDetails
                              subQuestions={subQuestions}
                              question={question}
                              key={question.questionId}
                              answers={answers}
                              formCompleted={true}
                            />
                          );
                        })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default TradeReferenceDashboard;
