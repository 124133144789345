import React, { useState } from "react";
import { AppConfig } from "../../../configs/admin-config";
import MuiAlert from "@material-ui/lab/Alert";
import { Typography, Button, Grid, TextField, Snackbar, Backdrop, CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
  },
  title: {
    marginBottom: 10,
    fontWeight: 500,
  },
  table: {},
  btnContainer: {
    marginTop: 10,
  },
  btn: {
    textTransform: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RatioTable = (props) => {
  const classes = useStyles();
  const [isEditable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropOpen, setbackDrop] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [formErrorSnack, setFormError] = useState(false);
  const [cloneRatios, setCloneRatios] = useState(JSON.parse(JSON.stringify(props.ratios)));

  const edit = () => {
    setEditable(true);
  };

  const cancel = () => {
    setEditable(false);
    setCloneRatios(JSON.parse(JSON.stringify(props.ratios)));
  };

  const submit = async () => {
    if (validateCreditScore()) {
      setbackDrop(true);
      const requestURL = AppConfig.baseUrl + AppConfig.ratioScales;

      try {
        let response = await fetch(requestURL, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAccessToken(),
          },
          body: JSON.stringify(cloneRatios),
        });
        let body = await response.json();
        if (response.status === 200) {
          setEditable(false);
          setSuccess(true);
        } else {
          console.error(body.message);
          setFail(true);
        }
        setbackDrop(false);
        return null;
      } catch (error) {
        setbackDrop(false);
        console.error(error);
        return error;
      }
    } else {
      setFormError(true);
    }
  };

  const validateCreditScore = () => {
    let result = true;
    cloneRatios.map((item) => {
      for (var key in item) {
        if (item["credit_score"] === "") {
          result = false;
        }
      }
    });
    return result;
  };

  const handleChange = (ratio, category) => (e) => {
    setLoading(true);
    ratio[category] = category === "creditScore" ? e.target.value : parseFloat(e.target.value);
    setLoading(false);
  };

  function returnCell(ratio, value, category) {
    return (
      <React.Fragment>
        {!loading &&
          (isEditable ? (
            <TextField
              size="small"
              className={classes.cell1}
              onChange={handleChange(ratio, category)}
              error={
                category === "creditScore" &&
                cloneRatios.filter((item) => item.id === ratio.id)[0]["creditScore"] === ""
              }
              defaultValue={value}
              inputProps={{ size: 5, style: { textAlign: "end" } }}
            />
          ) : (
            value
          ))}
      </React.Fragment>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
    setFormError(false);
  };

  return (
    <div>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>
      <Snackbar open={formErrorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Credit Score is Required
        </Alert>
      </Snackbar>
      <Typography variant="body2" className={classes.title}>
        {props.category}
      </Typography>

      <TableContainer component={Paper} className={classes.table}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Ratio Category</TableCell>
              <TableCell align="right">Min Value</TableCell>
              <TableCell align="right">Max Value</TableCell>
              <TableCell align="right">Credit Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cloneRatios.map((ratio) => (
              <TableRow key={ratio.id}>
                <TableCell>{ratio.ratioCategory}</TableCell>
                <TableCell align="right">{returnCell(ratio, ratio.minVal, "minVal")}</TableCell>
                <TableCell align="right">{returnCell(ratio, ratio.maxVal, "maxVal")}</TableCell>
                <TableCell align="right">{returnCell(ratio, ratio.creditScore, "creditScore")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.btnGrid}>
        <Grid container spacing={2} className={classes.btnContainer} justify="flex-end">
          <Grid item className={classes.submitItem}>
            {!isEditable && (
              <Button className={classes.btn} variant="outlined" size="small" color="primary" onClick={edit}>
                Edit
              </Button>
            )}
            {isEditable && (
              <Button className={classes.btn} variant="outlined" size="small" color="primary" onClick={cancel}>
                Cancel
              </Button>
            )}
          </Grid>
          <Grid item className={classes.submitItem}>
            <Button
              className={classes.btn}
              variant="contained"
              size="small"
              color="secondary"
              onClick={submit}
              disabled={!isEditable}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default RatioTable;
