import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    paddingLeft: "0.5%",
    paddingRight: "0.5%",
  },
  border: {
    border: "1px solid #ddd",
  },
  boldText: {
    fontWeight: 500,
  },
  tableHeading: {
    backgroundColor: "#F5F5F5",
  },
}));

const CreditScoreChecking = (props) => {
  const classes = useStyles();
  const [year1, setYear1] = useState({});
  const [year2, setYear2] = useState({});
  const [year3, setYear3] = useState({});
  const [ratios, setRatios] = useState([]);
  const [backdropOpen, setbackDrop] = useState(false);

  const getCreditScoreItems = async () => {
    if (props.currency === "US Dollar") {
      props.year1["exchangeRate"] = 1;
      props.year2["exchangeRate"] = 1;
      props.year3["exchangeRate"] = 1;
    }
    setYear1(convertToDollars(1, props.year1));
    setYear2(convertToDollars(2, props.year2));
    setYear3(convertToDollars(3, props.year3));
    setRatios(props.ratios);
    setbackDrop(false);
  };

  useEffect(() => {
    setbackDrop(true);
    getCreditScoreItems();
  }, []);

  const convertToDollars = (year, obj) => {
    let object = JSON.parse(JSON.stringify(obj));

    if (year === 1) {
      Object.keys(obj).forEach(function (key) {
        if (!(key === "companyId" || key === "year" || key === "exchangeRate")) {
          object[key] = Math.round((parseFloat(object[key]) / parseFloat(object["exchangeRate"])) * 100) / 100;
        } else {
          object[key] = object[key];
        }
      });
    }
    if (year === 2) {
      Object.keys(obj).forEach(function (key) {
        if (!(key === "companyId" || key === "year" || key === "exchangeRate")) {
          object[key] = Math.round((parseFloat(object[key]) / parseFloat(object["exchangeRate"])) * 100) / 100;
        } else {
          object[key] = object[key];
        }
      });
    }
    if (year === 3) {
      Object.keys(obj).forEach(function (key) {
        if (!(key === "companyId" || key === "year" || key === "exchangeRate")) {
          object[key] = Math.round((parseFloat(object[key]) / parseFloat(object["exchangeRate"])) * 100) / 100;
        } else {
          object[key] = object[key];
        }
      });
    }
    return object;
  };

  // Customer Financial Calculation Starts from Here

  function calculateCurrentRatio(year) {
    let currentRatio = 0;
    if (checkObjectComplete(year1) && year === 1) {
      currentRatio = formatNumber(Math.round((year1.currentAssets / year1.currentLiability) * 100) / 100);
      return isNaN(currentRatio) ? "-" : currentRatio;
    } else if (checkObjectComplete(year2) && year === 2) {
      currentRatio = formatNumber(Math.round((year2.currentAssets / year2.currentLiability) * 100) / 100);
      return isNaN(currentRatio) ? "-" : currentRatio;
    } else if (checkObjectComplete(year3) && year === 3) {
      currentRatio = formatNumber(Math.round((year3.currentAssets / year3.currentLiability) * 100) / 100);
      return isNaN(currentRatio) ? "-" : currentRatio;
    } else {
      return "";
    }
  }

  function calculateCashRatio(year) {
    let cashRatio = 0;
    if (year === 1) {
      cashRatio = formatNumber(Math.round(((year1.cash + year1.investments) / year1.currentLiability) * 100) / 100);
      return isNaN(cashRatio) ? "-" : cashRatio;
    } else if (year === 2) {
      cashRatio = formatNumber(Math.round(((year2.cash + year2.investments) / year2.currentLiability) * 100) / 100);
      return isNaN(cashRatio) ? "-" : cashRatio;
    } else if (year === 3) {
      cashRatio = formatNumber(Math.round(((year3.cash + year3.investments) / year3.currentLiability) * 100) / 100);
      return isNaN(cashRatio) ? "-" : cashRatio;
    }
  }

  function calculateWorkingCapitalRatio(year) {
    if (year === 1) {
      return formatNumber(Math.round((year1.currentAssets - year1.currentLiability) * 100) / 100);
    } else if (year === 2) {
      return formatNumber(Math.round((year2.currentAssets - year2.currentLiability) * 100) / 100);
    } else if (year === 3) {
      return formatNumber(Math.round((year3.currentAssets - year3.currentLiability) * 100) / 100);
    }
  }

  function calculateDebtRatio(year) {
    let debtRatio = 0;
    if (year === 1) {
      debtRatio = formatNumber(Math.round((year1.totalDebt / year1.totalAssets) * 100) / 100);
      return isNaN(debtRatio) ? "-" : debtRatio;
    } else if (year === 2) {
      debtRatio = formatNumber(Math.round((year2.totalDebt / year2.totalAssets) * 100) / 100);
      return isNaN(debtRatio) ? "-" : debtRatio;
    } else if (year === 3) {
      debtRatio = formatNumber(Math.round((year3.totalDebt / year3.totalAssets) * 100) / 100);
      return isNaN(debtRatio) ? "-" : debtRatio;
    }
  }

  function getRevenue(year) {
    if (year === 1) {
      return formatNumber(year1.revenue);
    } else if (year === 2) {
      return formatNumber(year2.revenue);
    } else if (year === 3) {
      return formatNumber(year3.revenue);
    }
  }

  function calculateRevenueGrowth(year) {
    let revenueGrowth = 0;
    if (year === 2) {
      revenueGrowth = formatNumber(Math.round(((year2.revenue - year1.revenue) / year1.revenue) * 10000) / 100);
      return isNaN(revenueGrowth) ? "" : revenueGrowth;
    } else if (year === 3) {
      revenueGrowth = formatNumber(Math.round(((year3.revenue - year2.revenue) / year2.revenue) * 10000) / 100);
      return isNaN(revenueGrowth) ? "" : revenueGrowth;
    }
  }

  function getProfit(year) {
    if (year === 1) {
      return formatNumber(year1.profit);
    } else if (year === 2) {
      return formatNumber(year2.profit);
    } else if (year === 3) {
      return formatNumber(year3.profit);
    }
  }

  function calculateCurrentAssetYoY(year) {
    let currentAssetYoY = 0;

    if (year === 2) {
      currentAssetYoY = formatNumber(
        Math.round(((year2.currentAssets - year1.currentAssets) / year1.currentAssets) * 10000) / 100
      );
      return isNaN(currentAssetYoY) ? "" : currentAssetYoY;
    } else if (year === 3) {
      currentAssetYoY = formatNumber(
        Math.round(((year3.currentAssets - year2.currentAssets) / year2.currentAssets) * 10000) / 100
      );
      return isNaN(currentAssetYoY) ? "" : currentAssetYoY;
    }
  }

  function calculateCurrentLiabilityYoY(year) {
    let currentLiabilityYoY = 0;

    if (year === 2) {
      currentLiabilityYoY = formatNumber(
        Math.round(((year2.currentLiability - year1.currentLiability) / year1.currentLiability) * 10000) / 100
      );
      return isNaN(currentLiabilityYoY) ? "" : currentLiabilityYoY;
    } else if (year === 3) {
      currentLiabilityYoY = formatNumber(
        Math.round(((year3.currentLiability - year2.currentLiability) / year2.currentLiability) * 10000) / 100
      );
      return isNaN(currentLiabilityYoY) ? "" : currentLiabilityYoY;
    }
  }

  // End of Calculating customer financials

  function formatNumber(number) {
    if (typeof number === "number") {
      return number;
    } else {
      return 0;
    }
  }

  function getRatio(category, year) {
    let returnValue = "";
    if (ratios.length > 0) {
      const scaleArray = ratios.filter((ratio) => ratio.ratioCategory === category);
      if (category === "Current ratio") {
        const value = calculateCurrentRatio(year);

        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (id === 0 && value === scale.minVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Cash ratio") {
        const value = calculateCashRatio(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (id === 0 && value === scale.minVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "WC") {
        const value = calculateWorkingCapitalRatio(year);
        scaleArray.map((scale) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Debt ratio") {
        const value = calculateDebtRatio(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (id === 0 && value === scale.minVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Revenue") {
        const value = getRevenue(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Revenue growth") {
        const value = calculateRevenueGrowth(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      }
    }
    if (returnValue) {
      return returnValue;
    } else {
      return "0";
    }
  }

  function getFinancialCreditScore(category) {
    let year = "";

    if (checkObjectComplete(year3)) {
      year = 3;
    } else if (checkObjectComplete(year2)) {
      year = 2;
    } else if (checkObjectComplete(year1)) {
      year = 1;
    }

    let returnValue = "";
    if (ratios.length > 0) {
      const scaleArray = ratios.filter((ratio) => ratio.ratioCategory === category);
      if (category === "Current ratio") {
        const value = calculateCurrentRatio(year);

        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (id === 0 && value === scale.minVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Cash ratio") {
        const value = calculateCashRatio(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (id === 0 && value === scale.minVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "WC") {
        const value = calculateWorkingCapitalRatio(year);
        scaleArray.map((scale) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Debt ratio") {
        const value = calculateDebtRatio(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (id === 0 && value === scale.minVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Revenue") {
        const value = getRevenue(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      } else if (category === "Revenue growth") {
        const value = calculateRevenueGrowth(year);
        scaleArray.map((scale, id) => {
          if (scale.minVal === null && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          } else if (scale.maxVal === null && value > scale.minVal) {
            returnValue = scale.creditScore.toString();
          } else if (value > scale.minVal && value <= scale.maxVal) {
            returnValue = scale.creditScore.toString();
          }
        });
      }
    }
    if (returnValue) {
      return returnValue;
    } else {
      return "0";
    }
  }

  function calculateTotalCreditScore(year) {
    let result =
      parseInt(getRatio("Current ratio", year)) +
      parseInt(getRatio("Cash ratio", year)) +
      parseInt(getRatio("WC", year)) +
      parseInt(getRatio("Debt ratio", year)) +
      parseInt(getRatio("Revenue", year)) +
      parseInt(getRatio("Revenue growth", year));

    return isNaN(result) ? "-" : result;
  }

  function calculateFinalTotalCreditScore() {
    let result =
      parseInt(getFinancialCreditScore("Current ratio")) +
      parseInt(getFinancialCreditScore("Cash ratio")) +
      parseInt(getFinancialCreditScore("WC")) +
      parseInt(getFinancialCreditScore("Debt ratio")) +
      parseInt(getFinancialCreditScore("Revenue")) +
      parseInt(getFinancialCreditScore("Revenue growth"));
    return result;
  }

  function calculateCreditRating(year) {
    let value = calculateTotalCreditScore(year);

    const scaleArray = ratios.filter((ratio) => ratio.ratioCategory === "Rating scale");
    let returnValue = "";
    scaleArray.map((scale, id) => {
      if (scale.minVal === null && value <= scale.maxVal) {
        returnValue = scale.creditScore.toString();
      } else if (scale.maxVal === null && value > scale.minVal) {
        returnValue = scale.creditScore.toString();
      } else if (value > scale.minVal && value <= scale.maxVal) {
        returnValue = scale.creditScore.toString();
      } else if (id === 0 && value === scale.minVal) {
        returnValue = scale.creditScore.toString();
      }
    });
    return returnValue;
  }

  function calculateFinalCreditRating(year) {
    let value = calculateFinalTotalCreditScore();

    const scaleArray = ratios.filter((ratio) => ratio.ratioCategory === "Rating scale");
    let returnValue = "";
    scaleArray.map((scale, id) => {
      if (scale.minVal === null && value <= scale.maxVal) {
        returnValue = scale.creditScore.toString();
      } else if (scale.maxVal === null && value > scale.minVal) {
        returnValue = scale.creditScore.toString();
      } else if (value > scale.minVal && value <= scale.maxVal) {
        returnValue = scale.creditScore.toString();
      } else if (id === 0 && value === scale.minVal) {
        returnValue = scale.creditScore.toString();
      }
    });
    return returnValue;
  }

  const checkObjectComplete = (obj) => {
    for (var key in obj) {
      if (obj[key] === "") return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <div>
        {!backdropOpen &&
          Object.keys(year1).length > 0 &&
          Object.keys(year2).length > 0 &&
          Object.keys(year3).length > 0 && (
            <TableContainer className={classes.border}>
              <Table size="small">
                <TableHead className={classes.tableHeading}>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>
                      Ratios
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Customer Financials
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      Credit Score
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Financial Credit Score
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Year 1</TableCell>
                    <TableCell align="center">Year 2</TableCell>
                    <TableCell align="center">Year 3</TableCell>
                    <TableCell align="center">Year 1</TableCell>
                    <TableCell align="center">Year 2</TableCell>
                    <TableCell align="center">Year 3</TableCell>
                  </TableRow>
                </TableHead>
                {true && (
                  <TableBody>
                    <TableRow>
                      <TableCell>Current ratio = Current Assets / Current Liability</TableCell>
                      <TableCell align="right">
                        {calculateCurrentRatio(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateCurrentRatio(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateCurrentRatio(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">{getRatio("Current ratio", 1)}</TableCell>
                      <TableCell align="right">{getRatio("Current ratio", 2)}</TableCell>
                      <TableCell align="right">{getRatio("Current ratio", 3)}</TableCell>
                      <TableCell align="right">{getFinancialCreditScore("Current ratio")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Cash ratio = (Cash + Investments) / Current Liability</TableCell>
                      <TableCell align="right">
                        {calculateCashRatio(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateCashRatio(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateCashRatio(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">{getRatio("Cash ratio", 1)}</TableCell>
                      <TableCell align="right">{getRatio("Cash ratio", 2)}</TableCell>
                      <TableCell align="right">{getRatio("Cash ratio", 3)}</TableCell>
                      <TableCell align="right">{getFinancialCreditScore("Cash ratio")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Working capital ratio = Current assets - Current liability</TableCell>
                      <TableCell align="right">
                        {calculateWorkingCapitalRatio(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateWorkingCapitalRatio(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateWorkingCapitalRatio(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">{getRatio("WC", 1)}</TableCell>
                      <TableCell align="right">{getRatio("WC", 2)}</TableCell>
                      <TableCell align="right">{getRatio("WC", 3)}</TableCell>
                      <TableCell align="right">{getFinancialCreditScore("WC")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Debt ratio = Total debt / Total assets</TableCell>
                      <TableCell align="right">
                        {calculateDebtRatio(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateDebtRatio(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {calculateDebtRatio(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">{getRatio("Debt ratio", 1)}</TableCell>
                      <TableCell align="right">{getRatio("Debt ratio", 2)}</TableCell>
                      <TableCell align="right">{getRatio("Debt ratio", 3)}</TableCell>
                      <TableCell align="right">{getFinancialCreditScore("Debt ratio")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Revenue</TableCell>
                      <TableCell align="right">
                        {getRevenue(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {getRevenue(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {getRevenue(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">{getRatio("Revenue", 1)}</TableCell>
                      <TableCell align="right">{getRatio("Revenue", 2)}</TableCell>
                      <TableCell align="right">{getRatio("Revenue", 3)}</TableCell>
                      <TableCell align="right">{getFinancialCreditScore("Revenue")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Revenue growth %</TableCell>
                      <TableCell align="right">{year1.revenue ? "0.00" : null}% </TableCell>
                      <TableCell align="right">
                        {calculateRevenueGrowth(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %
                      </TableCell>
                      <TableCell align="right">
                        {calculateRevenueGrowth(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %
                      </TableCell>
                      <TableCell align="right">0</TableCell>
                      <TableCell align="right">{getRatio("Revenue growth", 2)}</TableCell>
                      <TableCell align="right">{getRatio("Revenue growth", 3)}</TableCell>
                      <TableCell align="right">{getFinancialCreditScore("Revenue growth")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Profit</TableCell>
                      <TableCell align="right">
                        {getProfit(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {getProfit(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                      <TableCell align="right">
                        {getProfit(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Current asset YoY %</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {calculateCurrentAssetYoY(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %
                      </TableCell>
                      <TableCell align="right">
                        {calculateCurrentAssetYoY(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Current liability YoY %</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {calculateCurrentLiabilityYoY(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %
                      </TableCell>
                      <TableCell align="right">
                        {calculateCurrentLiabilityYoY(3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.emptyRow}>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <br></br>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.boldText}>Total Credit Score</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right" className={classes.boldText}>
                        {calculateTotalCreditScore(1)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.boldText}
                        style={{
                          backgroundColor:
                            calculateTotalCreditScore(2) < calculateTotalCreditScore(1) ? "#FF7F50" : "#90EE90",
                        }}
                      >
                        {calculateTotalCreditScore(2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.boldText}
                        style={{
                          backgroundColor:
                            calculateTotalCreditScore(3) < calculateTotalCreditScore(2) ? "#FF7F50" : "#90EE90",
                        }}
                      >
                        {calculateTotalCreditScore(3)}
                      </TableCell>
                      <TableCell align="right" className={classes.boldText}>
                        {calculateFinalTotalCreditScore()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.boldText}>Credit Rating</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right" className={classes.boldText}>
                        {calculateCreditRating(1)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.boldText}
                        style={{
                          backgroundColor: calculateCreditRating(2) > calculateCreditRating(1) ? "#FF7F50" : "#90EE90",
                        }}
                      >
                        {calculateCreditRating(2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.boldText}
                        style={{
                          backgroundColor: calculateCreditRating(3) > calculateCreditRating(2) ? "#FF7F50" : "#90EE90",
                        }}
                      >
                        {calculateCreditRating(3)}
                      </TableCell>
                      <TableCell align="right" className={classes.boldText}>
                        {calculateFinalCreditRating()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
      </div>
    </React.Fragment>
  );
};

export default CreditScoreChecking;
