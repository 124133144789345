import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Paper } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Resellers from "./admin-app/Resellers/Resellers";
import TradeReferences from "./admin-app/TradeReferences/TradeReferences";
import Preferences from "./admin-app/Preferences/Preferences";
import { checkIfRoleExists } from "../utils/oauth";
import { AppConfig, roles } from "../configs/admin-config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "48px",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
    paddingBottom: 20,
  },
  container: {
    zIndex: 1,
    paddingTop: 40,
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
  tabs: {
    textTransform: "none",
  },
  tabs2: {
    textTransform: "none",
    marginLeft: "auto",
    textAlign: "right",
    float: "right",
  },
  tabPanel: {
    backgroundColor: "white",
  },
  tabPanel2: {
    backgroundColor: "white",
  },
}));

const LandingPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [tabId, setTabId] = React.useState(0);

  useEffect(() => {
    // Set tab index for tabs in landing page
    if (props.match.params.tabName === "partners") {
      setTabId(0);
    } else if (props.match.params.tabName === "tradereferences") {
      setTabId(1);
    } else if (props.match.params.tabName === "preferences" && checkIfRoleExists(roles.admin)) {
      setTabId(2);
    }
  }, [props.match.params.tabName]);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      history.replace({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + (AppConfig.tabs[0] ? AppConfig.tabs[0] : ""),
      });
    } else if (newValue === 1) {
      history.replace({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + (AppConfig.tabs[1] ? AppConfig.tabs[1] : ""),
      });
    } else if (newValue === 2) {
      history.replace({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.LANDING_PAGE + (AppConfig.tabs[2] ? AppConfig.tabs[2] : ""),
      });
    }
    setTabId(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Paper>
          <AppBar position="static" className={classes.appBar}>
            <Tabs value={tabId} onChange={handleChange} scrollButtons="auto">
              <Tab className={classes.tabs} label="Partners" />
              <Tab className={classes.tabs} label="Trade References" />
              <Tab className={classes.tabs2} label="Preferences" disabled={!checkIfRoleExists(roles.admin)} />
            </Tabs>
          </AppBar>
          <TabPanel className={classes.tabPanel} value={tabId} index={0}>
            <Resellers />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={tabId} index={1}>
            <TradeReferences />
          </TabPanel>
          <TabPanel className={classes.tabPanel2} value={tabId} index={2}>
            <Preferences />
          </TabPanel>
        </Paper>
      </div>
    </div>
  );
};

export default LandingPage;
