// config values related to accessing ballerina service
const appContext = "/";

export const API_KEY = "API_KEY";
export const CLIENT = "client/";

export const AppConfig = {
  baseUrl: window.config?.REACT_APP_CLIENT_SERVICE_URL + "/",
  financeEmail: "finance-group@wso2.com",

  urls: {
    APP_CONTEXT: appContext,
    ADMIN: appContext + "admin/",
    CLIENT: appContext + "client/",
    RESELLER_FORM: appContext + CLIENT + "resellerform",
    RESELLER_FORM_VALIDATE: appContext + CLIENT + "resellerform/validate/",
    RESELLER_FORM_COMPLIANCE: appContext + CLIENT + "resellerform/compliance/",
    TRADE_REFERENCE_FORM: appContext + CLIENT + "tradereferenceform/",
    TRADE_REFERENCE_FORM_VALIDATE: appContext + CLIENT + "tradereferenceform/validate/",
    NO_ACCESS_PAGE: appContext + CLIENT + "403/",
    NOT_FOUND_PAGE: appContext + CLIENT + "404/",
  },

  //Reseller Information Endpoints
  validateEmail: "validate/partners/",
  getResellerQuestionData: "partners/",
  getResellerData: "partners",
  getCreditScoreItems: "credit-score-items",
  insertResellerInfo: "partners",
  updateResellerInfo: "partners",
  updateResellerFormStatus: "/form-status",
  insertCreditScoreItems: "credit-score-items",
  getResellerDataByTradeRefLink: "trade-references/partners",
  fileUpload: "file",
  fileDelete: "file",
  signatureUpload: "signatureUpload",
  countries: "countries",
  cpiData: "cpi",

  //Trade Reference Information Endpoints
  validateTradeRefEmail: "validate/trade-references/",
  getTradeReferenceQuestionData: "trade-references/questions",
  getResellerDataByCompanyId: "getResellerDataByCompanyId/",
  getTradeReferenceData: "trade-references",
  tradeRefLinks: "trade-reference/links",
  insertTradeRefInfo: "trade-reference/",
  updateTradeRefFormStatus: "trade-reference/status",
};

export const CountryList = {
  countries: [],
  US_States: [
    "AK",
    "AL",
    "AR",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
  ],
  Canada_States: ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"],
};

export const CurrencyList = {
  currencies: [
    "AED",
    "Australian Dollar",
    "Brazil",
    "British pound",
    "CHF",
    "Canadian Dollar",
    "Euro",
    "INR",
    "LKR",
    "MYR",
    "PHP",
    "SEK",
    "Singapore Dollar",
    "Thai baht",
    "US Dollar",
  ],

  scales: ["Actual", "Thousands (000)", "Millions (000,000)", "Billions (000,000,000)"],
};

export const VALIDATION = {
  LENGTH_10: 10,
  LENGTH_50: 50,
  LENGTH_100: 100,
  LENGTH_150: 150,
  LENGTH_250: 250,
  SINGLE_TEXTBOX_LENGTH: 500,
  MULTILINE_TEXTBOX_LENGTH: 1000,
};

export const TR_SUB_QUESTION_IDS = {
  TR1_COMPANY_NAME: 12,
  TR1_EMAIL: 15,
  TR2_COMPANY_NAME: 17,
  TR2_EMAIL: 20,
};
