import { useReducer, useCallback } from "react";
import { getAccessToken, handleTokenFailure } from "../utils/oauth";

const httpReducer = (curHttpState, action) => {
  switch (action.type) {
    case "SEND":
      return { loading: true, error: null, data: null, extra: action.extra, open: true };
    case "RESPONSE":
      return { ...curHttpState, loading: false, data: action.responseData, extra: action.extra, open: false };
    case "NO RESPONSE":
      return { loading: false, extra: action.extra, open: false };
    case "ERROR":
      return { loading: false, extra: action.extra, error: action.errorMessage };
    case "CLEAR":
      return { ...curHttpState, error: null };
    default:
      throw new Error("Should not get here");
  }
};

const useHttp = () => {
  const MAX_TRIES = 4;
  const [httpState, dispatchHTTP] = useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
    extra: null,
    open: false,
  });

  const sendRequest = useCallback((url, method, body, reqExtra, isRetry) => {
    var encodedUrl = encodeURI(url);
    dispatchHTTP({ type: "SEND", extra: reqExtra });
    fetch(encodedUrl, {
      method: method,
      body: body ? body : null,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getAccessToken(),
      },
    })
      .then((response) => {
        if ((response.status === 200 || response.status === 201) && response.body) {
          return response.json();
        } else if (response.status >= 400) {
          if (isRetry) {
            dispatchHTTP({
              type: "ERROR",
              extra: reqExtra,
              errorMessage:
                "Try reloading the page. If the issue continues, please contact internal-apps-group@wso2.com.",
            });
            return;
          }
          if (response.status === 401) {
            // refreshToken(()=>sendRequest(encodedUrl, method, body, reqExtra, true));
          } else {
            response
              .json()
              .then((errorResponse) => {
                dispatchHTTP({ type: "ERROR", extra: reqExtra, errorMessage: errorResponse });
              })
              .catch((err) => {
                dispatchHTTP({ type: "ERROR", extra: reqExtra, errorMessage: err });
              });

            return;
          }
        } else {
          dispatchHTTP({ type: "NO RESPONSE", extra: reqExtra });
          return;
        }
      })
      .then((response) => {
        if (response) {
          dispatchHTTP({ type: "RESPONSE", responseData: response, extra: reqExtra });
          return;
        }
      })
      .catch((er) => {
        dispatchHTTP({ type: "ERROR", extra: reqExtra, errorMessage: er });
        console.error("Error", er);
      });
  }, []);

  const sendRequestForImage = useCallback((url, method, body, reqExtra, isRetry) => {
    dispatchHTTP({ type: "SEND", extra: reqExtra });
    fetch(url, {
      method: method,
      body: body ? body : null,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + getAccessToken(),
      },
    })
      .then((response) => {
        if (response.status === 200 && response.body) {
          return response.body;
        } else {
          dispatchHTTP({ type: "NO RESPONSE", extra: reqExtra });
          return;
        }
      })
      .then((response) => {
        const reader = response.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => {
        let url = URL.createObjectURL(blob);
        dispatchHTTP({ type: "RESPONSE", responseData: url, extra: reqExtra });
      })
      .catch((err) => {
        dispatchHTTP({ type: "ERROR", extra: reqExtra });
        return;
      });
  }, []);

  const clearError = () => {
    dispatchHTTP({ type: "CLEAR" });
  };

  const handleRequest = async (url, method, body, successFn, failFn, loadingFn, currentTry) => {
    if (!currentTry) {
      currentTry = 0;
    }

    try {
      if (loadingFn) {
        loadingFn(true);
      }

      var encodedUrl = encodeURI(url);

      const response = await fetch(encodedUrl, {
        method: method,
        body: body ? JSON.stringify(body) : null,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let responseBody = { message: "Error! Please try again by refreshing the page." };
      try {
        responseBody = await response.json();
      } catch (e) {
        console.error(e);
      } finally {
        if (response.ok) {
          successFn(responseBody);
          if (loadingFn) {
            loadingFn(false);
          }
        } else {
          if (response.status === 401 && currentTry < MAX_TRIES) {
            responseBody = { message: "You are Unauthorized for this action/page!" };
            failFn(responseBody.message);
            handleTokenFailure(() =>
              handleRequest(encodedUrl, method, body, successFn, failFn, loadingFn, ++currentTry)
            );
          } else if (response.status === 403 && currentTry < MAX_TRIES) {
            responseBody = { message: "You are Forbidden for this action/page!" };
            failFn(responseBody.message);
            handleTokenFailure(() =>
              handleRequest(encodedUrl, method, body, successFn, failFn, loadingFn, ++currentTry)
            );
          } else if (currentTry < MAX_TRIES) {
            handleRequest(encodedUrl, method, body, successFn, failFn, loadingFn, ++currentTry);
          } else {
            if (failFn) {
              failFn(responseBody.message);
            }
            if (loadingFn) {
              loadingFn(false);
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      if (failFn) {
        failFn(err);
      }
    } finally {
      if (loadingFn) {
        loadingFn(false);
      }
    }
  };

  return {
    isLoading: httpState.loading,
    data: httpState.data,
    error: httpState.error,
    sendRequest,
    sendRequestForImage,
    handleRequest,
    clearError: clearError,
    reqExtra: httpState.extra,
    isOpen: httpState.open,
  };
};

export default useHttp;
