import React, { useEffect, useState } from "react";
import ReactPDF, { Page, Text, View, Image, Document, StyleSheet, Font, usePDF } from "@react-pdf/renderer";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import Roboto_Light from "../../resources/fonts/Roboto/Roboto-Light.ttf";
import Roboto_Bold from "../../resources/fonts/Roboto/Roboto-Bold.ttf";

// Create styles
// const useStyles = makeStyles((theme: Theme) => createStyles({
//     page: {
//         flexDirection: 'row',
//         backgroundColor: '#E4E4E4'
//       },
//       section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1
//       }
//   }));

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto_Light }, // font-style: normal, font-weight: normal
    { src: Roboto_Bold, fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  root: {
    fontFamily: "Roboto",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  body: {
    fontFamily: "Roboto",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 50,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign: "center",
    marginBottom: 5,
  },
  body1: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
  },
  smallHeader: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    // marginBottom: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  column: {
    float: "left",
    width: "50%",
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  left: {
    width: "50%",
  },

  right: {
    width: "50%",
  },
  image: {
    height: "auto",
    width: 150,
  },
  logo: {
    padding: 5,
    width: 150,
  },
  headingParent: {
    display: "flex",
  },
  headingIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  question: {
    // fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 5,
  },
  answer: {
    fontSize: 11,
    marginBottom: 10,
  },
});

const LegalQuestions = (props) => {
  useEffect(() => {}, [props.legalQA]);

  return (
    // <Page size="A4" style={styles.body}>
    <>
      <Text style={styles.smallHeader}>Legal Information</Text>
      <View style={styles.section}>
        <View style={styles.body1}>
          {/* <View style={styles.section}> */}
          {props?.legalQA &&
            Object.values(props.legalQA).map((e) => (
              <>
                <Text style={styles.question}>
                  {e?.question?.questionDescription ? " " + e.question.questionDescription : " N/A"}
                </Text>
                <Text style={styles.answer}>
                  {e?.subQuestion?.answerType
                    ? e?.answer?.booleanAnswer !== undefined
                      ? e?.answer?.booleanAnswer
                        ? e.answer?.descriptionAnswer
                          ? e.answer.descriptionAnswer
                          : "Yes"
                        : " No"
                      : " No"
                    : e?.subQuestion?.answerTypeDescription
                    ? e?.answer?.descriptionAnswer !== undefined
                      ? " " + e.answer.descriptionAnswer
                      : " N/A"
                    : e?.subQuestion?.answerTypeFileUpload
                    ? e?.answer?.fileCount > 0
                      ? " Yes"
                      : "No"
                    : " N/A"}
                </Text>
              </>
            ))}
          {/* </View> */}
        </View>
      </View>
    </>
    // </Page>
  );
};

// Create Document Component
const PDFCreator = (props) => {
  const [answers, setAnswers] = useState({});
  const [nameAndDesignation, setNameAndDesignationAnswers] = useState({
    name: "",
    designation: "",
    email: "",
  });

  useEffect(() => {
    setAnswers(props.answers);
    if (
      props.nameAndDesignation &&
      props.nameAndDesignation["Name"] !== undefined &&
      props.nameAndDesignation["Designation"] !== undefined &&
      props.nameAndDesignation["E-mail"] !== undefined
    ) {
      var tempObject = {
        name: props.nameAndDesignation["Name"],
        designation: props.nameAndDesignation["Designation"],
        email: props.nameAndDesignation["E-mail"],
      };
      setNameAndDesignationAnswers(tempObject);
    }
  }, [props.signatureImage, props.answers, props.nameAndDesignation, props.legalQA]);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.logo} src={"https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"} />

        <Text style={styles.title}>WSO2 Due Diligence Checklist</Text>
        <Text style={styles.body1}>
          Thank you for considering to be a part of the WSO2 partner program. Below you will find the information you
          entered to register your Company as a Reseller.
        </Text>
        <Text style={styles.smallHeader}>Company Information</Text>
        <View style={styles.section}>
          <View style={styles.body1}>
            <Text style={styles.question}>
              Company name:
              {/* </Text> */}
              {/* <Text style={styles.answer}> */}
              {answers?.companyName ? " " + answers.companyName : " N/A"}
            </Text>
            <Text style={styles.question}>
              City/Province: {answers?.cityProvince ? " " + answers.cityProvince : " N/A"}
            </Text>
            <Text style={styles.question}>
              Postal/Zip Code:
              {answers?.postalZipCode ? " " + answers.postalZipCode : " N/A"}
            </Text>
            <Text style={styles.question}>
              Year of incorporation:
              {answers?.yearOfIncorporation ? " " + answers.yearOfIncorporation : " N/A"}
            </Text>
            <Text style={styles.question}>
              No of current customers: {answers?.noOfCustomers ? " " + answers.noOfCustomers : " N/A"}
            </Text>
            <Text style={styles.question}>
              No of current employees: {answers?.noOfEmployees ? " " + answers.noOfEmployees : " N/A"}
            </Text>
            <Text style={styles.question}>
              Website Link: {answers?.websiteLink ? " " + answers.websiteLink : " N/A"}
            </Text>
            <Text style={styles.question}>
              Tax ID Number: {answers?.taxIdNumber ? " " + answers.taxIdNumber : " N/A"}
            </Text>
            <Text style={styles.question}>Country: {answers?.country ? " " + answers.country : " N/A"}</Text>
            <Text style={styles.question}>State: {answers?.state ? " " + answers.state : " N/A"}</Text>
            <Text style={styles.question}>
              Street Address: {answers?.streetAddress ? " " + answers.streetAddress : " N/A"}
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.body}>
        <LegalQuestions legalQA={props.legalQA} />

        <View style={styles.section}>
          {props.signatureImage && (
            <View style={styles.left}>
              <Image style={styles.image} src={props.signatureImage} />
            </View>
          )}

          <View style={styles.right}>
            <Text style={styles.question}>
              Name: {nameAndDesignation?.name ? " " + nameAndDesignation.name : " N/A"}
            </Text>
            <Text style={styles.question}>
              Designation: {nameAndDesignation?.designation ? " " + nameAndDesignation.designation : " N/A"}
            </Text>
            <Text style={styles.question}>
              Email: {nameAndDesignation?.email ? " " + nameAndDesignation.email : " N/A"}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// ReactPDF.render(<PDFViewer />);
export default PDFCreator;
