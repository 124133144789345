import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
  },
  descriptionParent: {
    display: "flex",
  },

  descriptionIndex: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "center",
    flexBasis: "3%",
    "@media(max-width: 900px)": {
      flexBasis: "3%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  descriptionDetails: {
    flexBasis: "97%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "95%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400p6x)": {
      flexBasis: "85%",
    },
  },
}));

const SubQuestionDetails = (props) => {
  const classes = useStyles();
  const question = props.question;
  const subQuestion = props.subQuestion;

  const getRenderBreakpoint = (id) => {
    let subQuestionIdList = [30, 31, 32, 33, 34, 35];
    if (subQuestionIdList.includes(id)) {
      return 6;
    } else {
      return 12;
    }
  };

  return (
    <Grid item xs={12} lg={getRenderBreakpoint(subQuestion.subQuestionId)} className={classes.verticalcenter}>
      {/* Render Description Inputs */}
      {question.questionId === subQuestion.questionId && (
        <div className={classes.descriptionParent}>
          <TextField
            name={subQuestion.subQuestionId + ""}
            size="small"
            label={subQuestion.description}
            autoComplete="nope"
            defaultValue={props.answer && props.answer[0] ? props.answer[0].answer : ""}
            variant="outlined"
            disabled={props.formCompleted}
            fullWidth
          />
        </div>
      )}
    </Grid>
  );
};

export default SubQuestionDetails;
