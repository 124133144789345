import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { makeStyles } from "@material-ui/styles";
import { AppConfig } from "../../../../../configs/admin-config";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import Fab from "@material-ui/core/Fab";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { getAccessToken } from "../../../../../utils/oauth";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: "center",
    display: "flex",
    paddingTop: 63,
    paddingBottom: 15,
    backgroundColor: "#F5F5F5",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    position: "fixed",
    right: "0",
  },
  document: {
    margin: "0 auto",
  },
  page: {
    marginBottom: 10,
  },
}));

const ViewPDF = (props) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.5);
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(
          `${AppConfig.baseUrl}${AppConfig.files}/${props.location.state.fileName}?fileExtension=pdf`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + getAccessToken(),
            },
          }
        );

        if (!response.ok) {
          console.error(response.message);
        }

        const blob = await response.blob();
        setFileData(URL.createObjectURL(blob));
      } catch (error) {
        console.error(error);
        return error;
      }
    };

    fetchPdf();
  }, [props.location.state.fileName]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const downloadPDF = () => {
    window.location.replace(AppConfig.baseUrl + AppConfig.files + "?filepath=" + props.location.state.fileName);
  };

  const zoomIn = () => {
    setScale((prev) => prev + 0.1);
  };

  const zoomOut = () => {
    setScale((prev) => prev - 0.1);
  };

  return (
    <React.Fragment>
      {props.location.state && (
        <div className={classes.alignCenter}>
          <Document
            loading="Please Wait..."
            file={fileData}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            className={classes.document}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} className={classes.page} />
            ))}
          </Document>

          <div className={classes.root}>
            <Fab color="primary" size="small">
              <ZoomInIcon onClick={zoomIn} />
            </Fab>
            <Fab color="primary" size="small">
              <ZoomOutIcon onClick={zoomOut} />
            </Fab>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewPDF;
