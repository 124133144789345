import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { TextField, Grid } from "@material-ui/core";

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />;
};
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
  },
  descriptionParent: {
    display: "flex",
  },

  descriptionIndex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexBasis: "3%",
    "@media(max-width: 900px)": {
      flexBasis: "3%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  descriptionDetails: {
    flexBasis: "97%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "95%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400p6x)": {
      flexBasis: "85%",
    },
  },
  datePicker: {
    width: "100%",
  },
}));

const SubQuestionDetails = (props) => {
  const classes = useStyles();
  const question = props.question;
  const subQuestion = props.subQuestion;

  const [fieldChanged, setFieldChanged] = useState(false);
  const [descAnswer, setDescriptionAnswer] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    props.answer && props.answer[0] ? props.answer[0].answer : null
  );

  // useEffect(()=> {
  //     if(props.answer && props.answer[0] && props.answer){
  //         setDescriptionAnswer(props.answer[0].answer)
  //     }
  // })

  useEffect(() => {
    if (props.answer && props.answer[0] && props.answer) {
      setDescriptionAnswer(props.answer[0].answer);
    }
    try {
      setSelectedDate(props.answer && props?.answer[0]?.answer ? Date(props.answer[0].answer) : null);
    } catch (e) {}
  }, [props.answer]);

  // Handle Text Inputs
  const handleFormChange = (subQuestionId) => (event) => {
    setDescriptionAnswer(event.target.value);
    setFieldChanged(true);

    // if(validateField2(event.target.value)===""){
    props.parentCallback(subQuestionId, event.target.value, true);
    // }
    // else{
    //     props.parentCallback(subQuestionId, event.target.value, false)
    // }
  };

  const handleDateChange = (date) => {
    let selectedDate = new Date(date);

    props.dateCallback(subQuestion.subQuestionId, selectedDate, true);
  };

  // Validation Function to show Errors in the Form UI
  const validateField = (date) => {
    // If form is submitted and inputs are empty
    if (props.isSubmitted && descAnswer === "" && subQuestion.answerRequired) {
      return "Required";
    } else if (props.isDrafted && descAnswer === "" && subQuestion.subQuestionId === 29) {
      return "Required";
    } else if (props.isDrafted && descAnswer === "" && subQuestion.subQuestionId === 39) {
      return "Required";
    } else if (props.isDrafted && descAnswer === "" && subQuestion.subQuestionId === 40) {
      return "Required";
    } else if (props.isDrafted && descAnswer === "" && subQuestion.subQuestionId === 41) {
      return "Required";
    }
    // When field is empty and not yet submitted
    else if (!props.isSubmitted && descAnswer === "") {
      return "";
    }

    // When field is empty and not yet drafted
    else if (!props.isDrafted && descAnswer === "") {
      return "";
    }
    // There's an input entered
    else {
      // There's a regex to be matched
      if (subQuestion.validationRegex !== "") {
        // If regex is matching with the input
        const regex = RegExp(subQuestion.validationRegex);

        // If form is a drafted form and returning no error
        if (props.answer && props.answer[0] && props.answer[0].descriptionAnswer && !fieldChanged) {
          return "";
        } else {
          if (regex.test(descAnswer)) {
            return "";
          }
          // If regex is not matching with the input
          else {
            // Returning error message to be displayed
            let ret = "Invalid Input";
            if (question.description !== "") {
              ret = "Please enter a valid " + question.questionDescription;
            }
            if (subQuestion.description !== "") {
              ret = "Please enter a valid " + subQuestion.description;
            }
            return ret;
          }
        }
      }
      // There's no regex to be matched
      else {
        return "";
      }
    }
  };

  // Validate Function to returning errors for parent Component
  const validateField2 = (answer) => {
    // When the form initializes, all the fields are valid
    if (!props.isSubmitted && !answer === "") {
      return "";
    }
    // If input is Empty after clicking on the field
    else if (answer === "") {
      return "Required";
    }
    // If form is submitted and inputs are empty
    else if (props.isSubmitted && !answer === "") {
      return "Required";
    }
    // There's an input entered
    else {
      // There's a regex to be matched
      if (subQuestion.validationRegex !== "") {
        // If regex is matching with the input
        const regex = RegExp(subQuestion.validationRegex);
        if (regex.test(answer)) {
          return "";
        }
        // If regex is not matching with the input
        else {
          // Returning error message to be displayed
          let ret = "Invalid Input";
          if (question.description !== "") {
            ret = "Please enter a valid " + question.questionDescription;
          }
          if (subQuestion.description !== "") {
            ret = "Please enter a valid " + subQuestion.description;
          }
          return ret;
        }
      }
      // There's no regex to be matched
      else {
        return "";
      }
    }
  };

  const getRenderBreakpoint = (id) => {
    let subQuestionIdList = [32, 33, 34, 35, 36, 37];
    if (subQuestionIdList.includes(id)) {
      return 6;
    } else {
      return 12;
    }
  };

  return (
    <Grid item xs={12} lg={getRenderBreakpoint(subQuestion.subQuestionId)} className={classes.verticalcenter}>
      {/* Render Description Inputs */}
      {question.questionId === subQuestion.questionId && subQuestion.answerDescription && (
        <div className={classes.descriptionParent}>
          <TextField
            name={subQuestion.subQuestionId + ""}
            size="small"
            onChange={handleFormChange(subQuestion.subQuestionId)}
            label={subQuestion.description}
            autoComplete="nope"
            value={props.answer && props.answer[0] ? props.answer[0].answer : ""}
            error={validateField() !== ""}
            helperText={validateField()}
            variant="outlined"
            disabled={props.formCompleted}
            fullWidth
          />
        </div>
      )}

      {question.questionId === subQuestion.questionId && subQuestion.description === "Date of last sale" && (
        <div className={classes.datePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label={subQuestion.description}
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              TextFieldComponent={TextFieldComponent}
              fullWidth
              inputVariant="outlined"
              size="small"
              emptyLabel="mm/dd/yyyy"
              error={validateField() !== ""}
              helperText={validateField()}
              disabled={props.formCompleted}
              maxDate={new Date()}
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
    </Grid>
  );
};

export default SubQuestionDetails;
