import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CreditScoreChecking from "./CreditScoreChecking";
import WorkingsForRatios from "./WorkingsForRatios";
import { useHistory } from "react-router-dom";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import { getAccessToken, checkIfRoleExists } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  root: {
    paddingLeft: "1%",
    paddingRight: "1%",
  },
  accordionSummary: {
    backgroundColor: theme.palette.primary.light,
  },
  accordionHeading: {
    fontSize: 14,
    fontWeight: 500,
  },
  accordionDetails: {
    padding: "2% 4%",
    display: "block",
  },
}));

const CreditScore = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [creditScorePanel, setCreditScorePanel] = useState(true);
  const [workingForRatioPanel, setWorkingForRatioPanel] = useState(true);
  const [year1, setYear1] = useState({});
  const [year2, setYear2] = useState({});
  const [year3, setYear3] = useState({});
  const [ratios, setRatios] = useState([]);
  const [currency, setCurrency] = useState("");
  const [loading, setLoading] = useState(true);
  const [backdropOpen, setbackDrop] = useState(true);

  const getCreditScoreItems = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.creditScoreItems + props.companyId;
    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          if (body.items.length > 0) {
            let year1Data =
              body.items.filter((data) => data.year === 1).length > 0
                ? body.items.filter((data) => data.year === 1)[0]
                : {};
            let year2Data =
              body.items.filter((data) => data.year === 2).length > 0
                ? body.items.filter((data) => data.year === 2)[0]
                : {};
            let year3Data =
              body.items.filter((data) => data.year === 3).length > 0
                ? body.items.filter((data) => data.year === 3)[0]
                : {};

            setYear1(year1Data);
            setYear2(year2Data);
            setYear3(year3Data);
          }
          if (body.ratios.length > 0) {
            setRatios(body.ratios);
          }
          if (body.currency.length > 0) {
            let currency = body.currency[0].descriptionAnswer;
            setCurrency(currency);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.warn(body.message);
      }
      setLoading(false);
      setbackDrop(false);

      return null;
    } catch (error) {
      setLoading(false);
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    if (checkIfRoleExists(roles.finance)) {
      getCreditScoreItems();
    } else {
      history.push({
        pathname: AppConfig.urls.NO_ACCESS_PAGE,
      });
    }
  }, [history]);

  const handleChange = (panel) => (event) => {
    if (panel === "creditScorePanel") {
      setCreditScorePanel(!creditScorePanel);
    }
    if (panel === "workingForRatioPanel") {
      setWorkingForRatioPanel(!workingForRatioPanel);
    }
  };

  const callBack = async () => {
    setLoading(true);
    const requestURL = AppConfig.baseUrl + AppConfig.creditScoreItems + props.companyId;
    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body) {
          if (body.items.length > 0) {
            let year1Data =
              body.items.filter((data) => data.year === 1).length > 0
                ? body.items.filter((data) => data.year === 1)[0]
                : {};
            let year2Data =
              body.items.filter((data) => data.year === 2).length > 0
                ? body.items.filter((data) => data.year === 2)[0]
                : {};
            let year3Data =
              body.items.filter((data) => data.year === 3).length > 0
                ? body.items.filter((data) => data.year === 3)[0]
                : {};

            setYear1(year1Data);
            setYear2(year2Data);
            setYear3(year3Data);
          }
          if (body.ratios.length > 0) {
            setRatios(body.ratios);
          }
          if (body.currency.length > 0) {
            let currency = body.currency[0].descriptionAnswer;
            setCurrency(currency);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }

      setbackDrop(false);
      setLoading(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      setLoading(false);
      console.error(error);
      return error;
    }
  };

  return (
    <div className={classes.root}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      {Object.keys(year1).length > 0 &&
        Object.keys(year2).length > 0 &&
        Object.keys(year3).length > 0 &&
        Object.keys(ratios).length > 0 &&
        !loading && (
          <Accordion component={Paper} onChange={handleChange("creditScorePanel")} expanded={creditScorePanel}>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.accordionHeading}>Credit Score Checking</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <CreditScoreChecking
                companyId={props.companyId}
                year1={year1}
                year2={year2}
                year3={year3}
                ratios={ratios}
                currency={currency}
              />
            </AccordionDetails>
          </Accordion>
        )}
      {Object.keys(ratios).length > 0 && !loading && (
        <Accordion component={Paper} onChange={handleChange("workingForRatioPanel")} expanded={workingForRatioPanel}>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.accordionHeading}>Workings for Ratios</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <WorkingsForRatios
              companyId={props.companyId}
              year1={year1}
              year2={year2}
              year3={year3}
              ratios={ratios}
              currency={currency}
              callBack={callBack}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default CreditScore;
