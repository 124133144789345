import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tooltip,
  TextField,
  Chip,
  IconButton,
  CircularProgress,
  Snackbar,
  Backdrop,
  Grid,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import { AppConfig, roles } from "../../../../../configs/admin-config";
import Avatar from "@material-ui/core/Avatar";
import { getAccessToken, getUserName, checkIfRoleExists } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  parentDiv: {
    marginBottom: 15,
  },
  questionParent: {
    display: "flex",
  },
  questionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  questionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  addCommentBtn: {
    top: 5,
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "white",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  outer: {
    position: "relative",
  },
  inner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  saveButton: {
    top: 15,
    textTransform: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CommentInputs = (props) => {
  const user = getUserName();
  const userEmail = getUserName();
  const classes = useStyles();
  const [commentsArray, setCommentsArray] = useState(
    props.comments.filter((c) => c.questionId === props.questionId).length > 0 ? [] : [1]
  );
  const [comments, setComments] = useState([]);
  const [allComments, setAllComments] = useState(props.comments);
  const [backdropOpen, setbackDrop] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [errorSnack, setError] = useState(false);
  const [currentComment, setCurrentComment] = useState("");

  const incrementCommentCount = () => {
    setCommentsArray((prev) => [...prev, 1]);
  };

  const handleFormChange = (index) => (event) => {
    setCurrentComment(event.target.value);
    let tempComments = comments.filter((c) => c.sortIndex !== index);

    const comment = {
      sortIndex: index,
      userEmail: getUserName(),
      comment: event.target.value,
      questionId: parseInt(props.questionId),
      companyId: parseInt(props.companyId),
    };
    tempComments.push(comment);
    setComments(tempComments);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const save = async () => {
    setbackDrop(true);
    // CurrentComment : Keep track of the entering comment to enable/disbale the Save button in Finance Opinion
    setCurrentComment("");
    setCommentsArray([]);

    const requestURL = AppConfig.baseUrl + AppConfig.financeComments;

    comments.map((comment) => {
      return allComments.push(comment);
    });

    setComments([]);

    try {
      let response = await fetch(requestURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(comments),
      });
      let body = await response.json();
      if (response.status === 200) {
        setSuccess(true);
        props.formRender();
      } else {
        console.error(body.message);
        setError(true);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  return (
    <div className={classes.parentDiv}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Failed!
        </Alert>
      </Snackbar>
      {/* Finance opinion text box */}
      <div className={classes.questionParent}>
        <div className={classes.questionIndex}></div>
        <div className={classes.questionDetails}>
          {allComments
            .filter((comment) => comment.questionId === props.questionId)
            .map((item, key) => {
              return (
                <Grid container spacing={1} key={key}>
                  <Grid item xs={12} sm={2} md={1} className={classes.outer}>
                    <Tooltip title={item.userEmail}>
                      <Chip
                        icon={<Avatar />}
                        className={classes.inner}
                        label={item.userName}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={10} md={11}>
                    <TextField
                      defaultValue={item.comment}
                      size="small"
                      onBlur={handleFormChange(key)}
                      variant="outlined"
                      disabled={true}
                      multiline={true}
                      minRows={2}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              );
            })}
          {commentsArray.map((item, key) => {
            return (
              <Grid container spacing={1} key={props.comments.length + key}>
                <Grid item xs={12} sm={2} className={classes.outer}>
                  <Tooltip title={userEmail}>
                    <Chip
                      style={{ maxWidth: 150 }}
                      icon={<Avatar />}
                      className={classes.inner}
                      label={user}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={10}>
                  {props.questionId === 42 && (
                    <TextField
                      size="small"
                      onChange={handleFormChange(props.comments.length + key)}
                      variant="outlined"
                      multiline={true}
                      minRows={2}
                      disabled={!checkIfRoleExists(roles.financeCreator)}
                      fullWidth
                    />
                  )}
                  {props.questionId === 43 && (
                    <TextField
                      size="small"
                      onChange={handleFormChange(props.comments.length + key)}
                      variant="outlined"
                      multiline={true}
                      minRows={2}
                      disabled={!checkIfRoleExists(roles.financeReviewer)}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </div>
      </div>

      {/* Adding a new finance opinion */}
      {/* Add icon */}
      {((props.questionId === 42 && checkIfRoleExists(roles.financeCreator)) ||
        (props.questionId === 43 && checkIfRoleExists(roles.financeReviewer))) && (
        <div>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}> </div>
            <div className={classes.questionDetails}>
              <Grid container spacing={1}>
                <Grid item xs={2} className={classes.outer}></Grid>
                <Grid item xs={10}>
                  <Tooltip title="New Comment" placement="right">
                    <span>
                      <IconButton
                        className={classes.addCommentBtn}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={incrementCommentCount}
                        disabled={
                          props.comments.filter((c) => c.questionId === props.questionId).length === 0 ||
                          commentsArray.length === 1
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.questionParent}>
            <div className={classes.questionIndex}> </div>
            <div className={classes.questionDetails}>
              <Grid container spacing={1}>
                <Grid item xs={1} className={classes.outer}></Grid>
                <Grid item xs={11}>
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="secondary"
                    disabled={currentComment === ""}
                    size="small"
                    onClick={save}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentInputs;
