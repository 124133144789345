import React from "react";
import { makeStyles } from "@material-ui/styles";
import { AppConfig } from "../../../../../configs/admin-config";
import useHttp from "../../../../../utils/http";
import { useEffect } from "react";
import { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageDiv: {
    textAlign: "center",
    height: "10%",
    marginTop: 48,
  },
  image: {
    textAlign: "center",
    maxWidth: "100%",
    height: "auto",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

const ViewImage = (props) => {
  const classes = useStyles();
  const [backdropOpen, setbackDrop] = useState(false);
  const [url, setUrl] = useState(null);
  const { isLoading, data, error, sendRequestForImage, reqExtra, isOpen } = useHttp();

  const getImage = (fileName, fileExtension) => {
    setbackDrop(true);
    if (fileName && fileExtension) {
      sendRequestForImage(
        AppConfig.baseUrl + AppConfig.files + "/" + fileName + "?fileExtension=" + fileExtension,
        "GET",
        null,
        AppConfig.files
      );
    }
  };

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.files:
        if (data) {
          setbackDrop(false);
          setUrl(data);
        }
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  useEffect(() => {
    getImage(props.location.state.fileName, props.location.state.extension);
  }, [props.location.state.fileName, props.location.state.extension]);

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      {isLoading ? (
        <LinearProgress />
      ) : (
        props.location.state && (
          <div className={classes.imageDiv}>
            {url ? <img className={classes.image} src={url} alt={props.location.state.fileName} /> : "No image"}
          </div>
        )
      )}
    </React.Fragment>
  );
};

export default ViewImage;
