import React, { useState, useEffect } from "react";
import { AppConfig, roles } from "../../../configs/admin-config";
import { checkIfRoleExists } from "../../../utils/oauth";
import {
  AccordionDetails,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import RatioTable from "./RatioTable";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmailPreferences from "./EmailPreferences";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
  },
  accordionSummary: {
    // padding: "0% 4%",
    minHeight: "0px",
    margin: 0,
    backgroundColor: theme.palette.primary.light,
    fontWeight: 500,
  },
  paper1: {
    marginBottom: 20,
  },
  accordionDetails: {
    padding: "2% 2.3%",
    paddingBottom: 30,
  },
  title: {
    fontWeight: 600,
  },
  note: {
    paddingLeft: "2.25%",
    paddingRight: "2.25%",
    paddingTop: 30,
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 500,
  },
  note2: {
    paddingLeft: "2.25%",
    paddingRight: "2.25%",
    paddingTop: 30,
    fontSize: 14,
    fontWeight: 500,
  },
}));
const Preferences = () => {
  const classes = useStyles();
  const history = useHistory();
  const [ratios, setRatios] = useState([]);
  const [backdropOpen, setbackDrop] = useState(true);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);

  const getPreferences = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.getPreferences;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body && body.ratios.length > 0) {
          setRatios(body.ratios);
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  useEffect(() => {
    if (checkIfRoleExists(roles.admin)) {
      getPreferences();
    } else {
      history.push({
        pathname: AppConfig.urls.NO_ACCESS_PAGE,
      });
    }
  }, []);

  const handleChange1 = () => {
    setExpanded1(!expanded1);
  };
  const handleChange2 = () => {
    setExpanded2(!expanded2);
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Paper className={classes.paper1}>
        <Accordion className={classes.accordion} expanded={expanded2} onChange={handleChange2}>
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2" className={classes.title}>
              Notification Email Preferences
            </Typography>
          </AccordionSummary>
          <React.Fragment>
            <Typography className={classes.note2}>
              Add emails to be notified when Resellers and Trade References submit their forms
            </Typography>
          </React.Fragment>

          <AccordionDetails className={classes.accordionDetails}>
            {checkIfRoleExists(roles.admin) && <EmailPreferences />}
          </AccordionDetails>
        </Accordion>
      </Paper>

      {ratios.length > 0 && (
        <Paper className={classes.paper}>
          <Accordion className={classes.accordion} expanded={expanded1} onChange={handleChange1}>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" className={classes.title}>
                Credit Score Scale Preferences
              </Typography>
            </AccordionSummary>
            <React.Fragment>
              <Typography className={classes.note}>
                Note: Please leave Min Value/Max Value as empty if there's no any limit
              </Typography>
            </React.Fragment>

            <AccordionDetails className={classes.accordionDetails}>
              <Grid container spacing={4}>
                <Grid item lg={6} xs={12}>
                  <RatioTable
                    category="Rating scale"
                    ratios={ratios.filter((item) => item.ratioCategory === "Rating scale")}
                  ></RatioTable>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <RatioTable
                    category="Current ratio"
                    ratios={ratios.filter((item) => item.ratioCategory === "Current ratio")}
                  ></RatioTable>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <RatioTable
                    category="Cash ratio"
                    ratios={ratios.filter((item) => item.ratioCategory === "Cash ratio")}
                  ></RatioTable>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <RatioTable
                    category="Debt ratio"
                    ratios={ratios.filter((item) => item.ratioCategory === "Debt ratio")}
                  ></RatioTable>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <RatioTable
                    category="Revenue"
                    ratios={ratios.filter((item) => item.ratioCategory === "Revenue")}
                  ></RatioTable>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <RatioTable
                    category="Revenue growth"
                    ratios={ratios.filter((item) => item.ratioCategory === "Revenue growth")}
                  ></RatioTable>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <RatioTable category="WC" ratios={ratios.filter((item) => item.ratioCategory === "WC")}></RatioTable>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default Preferences;
