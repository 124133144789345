const appContext = "/";
export const API_KEY = "API_KEY";
export const APP_NAME = "Due Diligence App";
export const APP_REDIRECT_URL_KEY = "dd-app-redirect-url";

export const AUTH_CONFIG = {
  signInRedirectURL: `${window.config?.REACT_APP_AUTH_SIGNIN_REDIRECT_URL}`,
  signOutRedirectURL: `${window.config?.REACT_APP_AUTH_SIGNOUT_REDIRECT_URL}`,
  clientID: `${window.config?.REACT_APP_ASGARDEO_CLIENT_ID}`,
  serverOrigin: `${window.config?.REACT_APP_ASGARDEO_SERVER_ORIGIN}`,
  baseUrl: `${window.config?.REACT_APP_ASGARDEO_SERVER_ORIGIN}`,
  scope: ["openid", "profile", "email", "groups"],
};

export const CHOREO_HEADERS = {
  Authorization:
    "Basic " + btoa(`${window.config?.REACT_APP_CHOREO_CLIENT_ID}:${window.config?.REACT_APP_CHOREO_CLIENT_SECRET}`),
  "Content-Type": "application/x-www-form-urlencoded",
};

export const AppConfig = {
  externalUrl: window.config?.REACT_APP_CLIENT_BASE_URL + "/",
  baseUrl: window.config?.REACT_APP_ADMIN_SERVICE_URL + "/",
  FileServiceURL: "http://localhost:37091/getFile",
  defaultTabName: "partners",
  tabs: ["partners", "tradereferences", "preferences"],
  resellerDashboardTabs: ["profile", "finance", "legal", "approvalsummary", "creditscore"],
  // Page URL List
  urls: {
    APP_CONTEXT: appContext,
    ADMIN: appContext + "admin/",
    CLIENT: appContext + "client/",
    LANDING_PAGE: "home/",
    PENDING_PAGE: "partner-dashboard/pending/",
    RESELLER_DASHBOARD: "partner-dashboard/",
    PENDING_PAGE_2: "tradereference/pending/",
    TRADE_REFERENCE: "tradereference/",
    TRADE_REFERENCES: "tradereferences/",
    VIEW_PDF: "view-pdf/",
    VIEW_IMAGE: "view-image/",
    NO_ACCESS_PAGE: "403/",
    NOT_FOUND_PAGE: "404/",
    RESELLER_FORM: "resellerform",
    RESELLER_FORM_VALIDATE: "resellerform/validate/",
    RESELLER_FORM_COMPLIANCE: "resellerform/compliance/",
    TRADE_REFERENCE_FORM: "tradereferenceform/",
    TRADE_REFERENCE_FORM_VALIDATE: "tradereferenceform/validate/",
  },

  // Service Endpoints List
  partnerData: "partners/",
  tradeReferenceData: "trade-references/",
  formStatus: "form-status",
  partnerLink: "partner/link/",
  partnerQuestions: "partners/questions",
  partnerAnswers: "partners/answers",
  answers: "answers",
  files: "files",
  countries: "countries",
  creditScoreItems: "credit-score-items/",
  ratioScales: "ratio-scales/",
  notificationEmails: "emails/",
  financeFeedback: "finance/feedback/",
  financeComments: "finance/comments/",
  linkData: "link-id/",
  legalQuestions: "questions/legal",
  legalAnswers: "answers/legal",
  approvalSummary: "approval-summary/",
  legalApproval: "legal-approval",
  linkStatus: "link-status",
  tradeReferenceFormStatus: "trade-reference/form-status/",

  getResellerQuestionData: "getResellerInfoQuestions/",
  getResellerAnswers: "getResellerAnswers/",
  insertFinancialFeedback: "insertFinancialFeedback/",
  insertLegalApproval: "insertLegalApproval/",
  insertFinanceComments: "insertFinanceComments/",

  getLegalInfoQuestions: "getLegalInfoQuestions/",
  getLegalAnswers: "getLegalAnswers/",
  resFile: "res-file",
  getResellers: "partners/",
  getTradeReferences: "trade-references/",
  getTradeReferenceQuestionData: "trade-references/info/questions",
  getResellerDataByCompanyId: "partners/",
  sendApprovalEmail: "approval-email",

  getTradeReferenceLinkId: "getTradeRefLinkdId/",
  updateResellerFormStatus: "updateResellerFormStatus/",
  insertCreditScoreItems: "insertCreditScoreItems",
  getPreferences: "preferences",
  insertNotificationEmail: "emails/",
  deleteNotificationEmail: "deleteNotificationEmail",
  getNotificationEmails: "emails/",
  changeResellerExpiry: "changeResellerExpiry",
};

export const roles = window.config?.REACT_APP_ROLES;
