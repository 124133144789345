import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  FormControlLabel,
  Radio,
  FormControl,
  Snackbar,
  RadioGroup,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import QuestionDetails from "./QuestionDetails";
import { AppConfig } from "../../../../../configs/admin-config";
import useHttp from "../../../../../utils/http";
import { getAccessToken, getIdToken } from "../../../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  backgroundColor: {
    minHeight: "800px",
  },
  container: {
    flexGrow: 1,
  },
  justifyText: {
    textAlign: "justify",
  },
  paper: {
    marginTop: 20,
  },
  accordionSummary: {
    padding: "0% 4%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    minHeight: "5px",
    borderRadius: 5,
  },
  accordionDetails: {
    padding: "1.5% 4%",
    paddingBottom: 30,
  },
  headingParent: {
    display: "flex",
  },
  headingIndex: {
    flexBasis: "4%",
    fontWeight: "bold",
    "@media(max-width: 900px)": {
      flexBasis: "6%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  headingName: {
    flexBasis: "96%",
    textAlign: "justify",
    fontWeight: "bold",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
      textAlign: "justify",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
      textAlign: "justify",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
      textAlign: "justify",
    },
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "white",
  },
  radioFormControl: {
    marginTop: 10,
  },
  radioGrid: {
    marginTop: 15,
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  financialScoreGrid: {
    marginTop: 20,
    marginBottom: 20,
    fontStyle: "bold",
  },
  financialScorePaper: {
    textAlign: "end",
    border: "1px solid grey",
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    color: "grey",
  },
  legalResult: {
    border: "1px solid grey",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    color: "grey",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ResellerSummary = (props) => {
  const classes = useStyles();
  const { isLoading, data, error, sendRequest, reqExtra, isOpen, handleRequest } = useHttp();
  const [backdropOpen, setbackDrop] = useState(true);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [formRender, setFormRender] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [submitFiles, setSubmitFiles] = useState([]);
  const [financeComments, setFinanceComments] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("files");
  const [closed, setClosed] = useState([]);
  const [failSnack, setFail] = useState(false);

  const getResellerInfoQuestions = async () => {
    let requestURL = AppConfig.baseUrl + AppConfig.partnerQuestions;
    handleRequest(
      requestURL,
      "GET",
      null,
      (data) => {
        console.log(data);

        if (data) {
          if (data && !error) {
            if (data.subQuestionInfo) {
              setSubQuestions(data.subQuestionInfo);
            }
            if (data.questionInfo) {
              setQuestions(data.questionInfo);
            }
            if (data.questionCategoryInfo) {
              setQuestionCategories(data.questionCategoryInfo);
            }
          } else {
            console.warn("Not retrieved any data from backend");
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getResellerAnswers = async () => {
    let requestURL = AppConfig.baseUrl + AppConfig.partnerData + props.companyId + "/" + AppConfig.answers;
    handleRequest(
      requestURL,
      "GET",
      null,
      (data) => {
        console.log(data);

        if (data) {
          if (data && !error) {
            if (data.comments.length > 0) {
              setFinanceComments(data.comments);
            }
            if (data.answers.length > 0) {
              setAnswers(data.answers);

              let result = data.answers.filter((item) => item.questionId === 18);
              if (result.length > 0) {
                setSelectedRadio("references");
              }
            }
            if (data.files.length > 0) {
              setSubmitFiles(data.files);
            }
            setbackDrop(false);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
        getResellerInfoQuestions();
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    setbackDrop(true);
    getResellerAnswers();
  }, []);

  useEffect(() => {
    setbackDrop(true);
    getResellerAnswers();
  }, [formRender]);

  const handleChange = (index) => {
    if (closed.includes(index)) {
      setClosed(closed.filter((item) => item !== index));
    } else {
      setClosed((prev) => [...prev, index]);
    }
  };

  const formRenderCallback = () => {
    setFormRender(formRender + 1);
  };

  const financialRadioFilter = (categoryId, questionId) => {
    if (categoryId === 3) {
      if (selectedRadio === "files" && questionId === 17) {
        return true;
      }
      if (selectedRadio === "references" && (questionId === 18 || questionId === 19)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    switch (reqExtra) {
      case AppConfig.partnerQuestions:
        if (data) {
          if (data && !error) {
            if (data.subQuestionInfo) {
              setSubQuestions(data.subQuestionInfo);
            }
            if (data.questionInfo) {
              setQuestions(data.questionInfo);
            }
            if (data.questionCategoryInfo) {
              setQuestionCategories(data.questionCategoryInfo);
            }
          } else {
            console.warn("Not retrieved any data from backend");
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
        setbackDrop(false);
        break;
      case AppConfig.partnerAnswers:
        if (data) {
          if (data && !error) {
            if (data.comments.length > 0) {
              setFinanceComments(data.comments);
            }
            if (data.answers.length > 0) {
              setAnswers(data.answers);

              let result = data.answers.filter((item) => item.questionId === 18);
              if (result.length > 0) {
                setSelectedRadio("references");
              }
            }
            if (data.files.length > 0) {
              setSubmitFiles(data.files);
            }
            setbackDrop(false);
          }
        } else {
          console.warn("Not retrieved any data from backend");
        }
        getResellerInfoQuestions();
        setbackDrop(false);
        break;
      default:
        break;
    }
  }, [data, reqExtra, isOpen, isLoading, error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFail(false);
  };

  return (
    <div className={classes.backgroundColor}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>

      <Container className={classes.container}>
        {subQuestions.length > 0 &&
          questionCategories.length > 0 &&
          questionCategories.map((category, key) => {
            return (
              <React.Fragment key={key}>
                <Paper className={classes.paper} key={category.sortIndex}>
                  <Accordion
                    className={classes.accordion}
                    expanded={!closed.includes(category.sortIndex)}
                    onChange={() => handleChange(category.sortIndex)}
                  >
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    >
                      <Grid item xs={12} className={classes.headingParent}>
                        <Typography className={classes.headingIndex}>{category.sortIndex - 2}.</Typography>
                        <Typography className={classes.headingName}>{category.categoryName}</Typography>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Grid container spacing={1}>
                        {/* Radio Button to Filter Financial Fields */}
                        {category.categoryId === 3 && (
                          <Grid item xs={12} className={classes.radioGrid}>
                            <Typography>
                              You can either upload audited/unaudited files or reference company details as Financial
                              Information
                            </Typography>
                            <div className={classes.headingParent}>
                              <div className={classes.headingIndex}></div>
                              <FormControl component="fieldset" className={classes.radioFormControl} disabled>
                                <RadioGroup aria-label="gender" value={selectedRadio}>
                                  <FormControlLabel
                                    value="files"
                                    control={<Radio color="primary" />}
                                    label="Upload audited/unaudited files"
                                  />
                                  <FormControlLabel
                                    value="references"
                                    control={<Radio color="primary" />}
                                    label="Submit reference company details"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </Grid>
                        )}
                        {questions.length > 0 &&
                          subQuestions.length > 0 &&
                          questions.map((question) => {
                            return question.categoryId === category.categoryId &&
                              financialRadioFilter(category.categoryId, question.questionId) ? (
                              <QuestionDetails
                                companyId={props.companyId}
                                subQuestions={subQuestions}
                                question={question}
                                category={category}
                                selectedRadio={selectedRadio}
                                key={question.questionId}
                                formRender={formRenderCallback}
                                answers={answers}
                                files={submitFiles}
                                comments={financeComments}
                              />
                            ) : null;
                          })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              </React.Fragment>
            );
          })}
      </Container>
    </div>
  );
};

export default ResellerSummary;
