import React, { useState, useEffect } from "react";
import { AppConfig } from "../../../configs/admin-config";
import { Grid, TextField, Button, Chip, Snackbar, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import FaceIcon from "@material-ui/icons/Face";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
  },
  btn: {
    textTransform: "none",
    marginLeft: 30,
  },
  chipDiv: {
    marginTop: 15,
  },
  chip: {
    marginRight: 15,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EmailPreferences = () => {
  const classes = useStyles();
  const [backdropOpen, setbackDrop] = useState(false);
  const [successSnack, setSuccess] = useState(false);
  const [failSnack, setFail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [formRender, setFormRender] = useState(0);

  useEffect(() => {
    getEmailList();
  }, [formRender]);

  const getEmailList = async () => {
    const requestURL = AppConfig.baseUrl + AppConfig.notificationEmails;

    try {
      let response = await fetch(requestURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      });
      let body = await response.json();
      if (response.status === 200) {
        if (body && body.length > 0) {
          let filter = body.filter((item) => item.category === "formsubmit");
          let array = [];
          filter.map((item) => {
            array.push(item.email);
          });
          setEmailList(array);
        } else {
          console.warn("Not retrieved any data from backend");
        }
      } else {
        console.error(body.message);
      }

      setbackDrop(false);
      return null;
    } catch (error) {
      setbackDrop(false);
      console.error(error);
      return error;
    }
  };

  const validateEmail = (email) => {
    let result = true;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email !== "" && !re.test(String(email).toLowerCase())) {
      result = false;
    } else {
      result = true;
    }
    return result;
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    setEmailErrorMsg("");
  };

  const handleDelete = async (email) => {
    setbackDrop(true);
    setEmailList(emailList.filter((e) => e !== email));
    const data = {
      email: email,
    };
    const requestURL = AppConfig.baseUrl + AppConfig.notificationEmails;

    try {
      let response = await fetch(requestURL, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
        body: JSON.stringify(data),
      });

      // let body = await response.json();
      if (response.status === 200) {
        setSuccess(true);
        setFormRender(formRender + 1);
        getEmailList();
      } else {
        setFail(true);
      }
      setbackDrop(false);
      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const addEmail = async () => {
    if (email === "") {
      setEmailError(true);
      setEmailErrorMsg("Please enter an Email");
    } else if (!validateEmail(email)) {
      setEmailError(true);
      setEmailErrorMsg("Please enter a valid Email");
    } else if (emailList.includes(email)) {
      setEmailError(true);
      setEmailErrorMsg("Already Exists");
    } else {
      setbackDrop(true);

      const requestURL = AppConfig.baseUrl + AppConfig.insertNotificationEmail;

      try {
        let response = await fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAccessToken(),
          },
          body: JSON.stringify({
            email: email,
            category: "formsubmit",
          }),
        });
        let body = await response.json();
        if (response.status === 201) {
          setSuccess(true);
          setFormRender(formRender + 1);
          getEmailList();
        } else {
          console.error(body.message);
          setFail(true);
        }
        setbackDrop(false);
        return null;
      } catch (error) {
        setbackDrop(false);
        console.error(error);
        return error;
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setFail(false);
  };

  return (
    <React.Fragment>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Snackbar open={successSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failSnack} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Server Error!
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} xs={8}>
          <TextField
            label="Email Address"
            variant="outlined"
            size="small"
            fullWidth
            onChange={emailHandler}
            error={emailError}
            helperText={emailErrorMsg}
          />
        </Grid>
        <Grid item lg={8} md={6} xs={4}>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            onClick={addEmail}
            disabled={emailError}
          >
            Add
          </Button>
        </Grid>

        <div className={classes.chipDiv}>
          {emailList.length > 0 &&
            emailList.map((email) => {
              return (
                <Chip
                  className={classes.chip}
                  icon={<FaceIcon />}
                  label={email}
                  color="primary"
                  variant="outlined"
                  onDelete={() => handleDelete(email)}
                  deleteIcon={<DeleteIcon />}
                />
              );
            })}
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default EmailPreferences;
