import App from "./App";
import { render } from "react-dom";
import { AuthProvider } from "@asgardeo/auth-react";
import { AUTH_CONFIG } from "./configs/admin-config";

const Index = () => {
  return (
    <AuthProvider config={AUTH_CONFIG}>
      <App />
    </AuthProvider>
  );
};

render(<Index />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
